<script>
// import { isWorkWeixin } from '@/common/weixin/index.js'
import { defineComponent, getCurrentInstance, h, nextTick, onUpdated } from 'vue'
import { useStore } from 'vuex'
const ChineseTextReg = /[\u4e00-\u9fa5]/g
export default defineComponent({
  name: 'wxview',
  props: {
    type: {
      type: String,
      required: true
    },
    openid: {
      type: [String, null],
      default: '', // 设置default 以免外面传入undefined,爆出警告
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const isWxView = store.state.isWxView || false
    let cropPattern = localStorage.getItem('cropPattern')
    // 获取vue 实例
    const instance = getCurrentInstance()
    // 判断是否为微信环境
    // const isWeixin = isWorkWeixin()
    onUpdated(() => {
      nextTick(() => {
        const $el = instance.ctx.$el
        if (window.WWOpenData && $el && cropPattern == 0 && isWxView && !ChineseTextReg.test(props.openid)) {
          window.WWOpenData.bind($el)
        }
      })
    })
    return () => {
      const openId = props.openid
      if (!openId) {
        return h('span', '--')
      } else if (ChineseTextReg.test(openId)) {
        return h('span', openId)
      } else if (cropPattern == 0 && isWxView && !ChineseTextReg.test(openId)) {
        return h('ww-open-data', { type: props.type, openid: openId })
      } else {
        if (props.type === 'userName' && isWxView && !ChineseTextReg.test(openId)) {
          return h('ww-open-data', { type: props.type, openid: openId })
        } else {
          return h('span', openId)
        }
      }
    }
  }
})
</script>
