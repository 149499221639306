import { reactive, ref, computed } from 'vue'
import { Base64 } from 'js-base64'
import { getTreeDepartment, getAgentConfig, getEmployeeList, logout, loginLog, isLogin, getWeChatSetting } from '@/apis'
import { agentConfig } from '@/common/weixin'
import { M_IS_WX_VIEW, DEPARTMENT_TREE_DATA } from '@/store/types'
import { useDeleteConfirm } from './ui'
import store from '@/store'
import router from '@/router'

export const useEmployee = () => {
  const employeeState = reactive({
    list: [],
    count: 0,
    loading: false,
    query: {
      pageSize: 10,
      pageNo: 1,
      name: '',
      mobile: '',
      department: '',
      identity: '',
      positionNos: ''
    }
  })
  // 获取部门员工列表
  const initEmployeeList = async (departmentId, pageNo, pageSize, mobile, name) => {
    if (!departmentId) {
      throw new Error('[initEmployeeList]:部门ID不能为空')
    }
    employeeState.loading = true
    try {
      employeeState.query.department = departmentId
      employeeState.query.mobile = mobile || employeeState.query.mobile
      employeeState.query.pageNo = pageNo || employeeState.query.pageNo
      employeeState.query.name = name || employeeState.query.name
      employeeState.query.pageSize = pageSize || employeeState.query.pageSize
      const result = await getEmployeeList(employeeState.query)
      const { data } = result || {}
      employeeState.list = data?.list || []
      employeeState.count = data?.count || 0
    } catch (error) {
      console.log('[initEmployeeList]:Error', error)
    } finally {
      employeeState.loading = false
    }
  }
  // 获取指定身份编号员工列表
  const initEmployeeListByIdentity = async (identity, pageNo, pageSize) => {
    if (!identity) {
      throw new Error('[initEmployeeListByIdentity]:身份编号identity不能为空')
    }
    try {
      employeeState.query.identity = identity
      employeeState.query.pageNo = pageNo || employeeState.query.pageNo
      employeeState.query.pageSize = pageSize || employeeState.query.pageSize
      const result = await getEmployeeList(employeeState.query)
      const { data } = result || {}
      employeeState.list = data?.list || []
      employeeState.count = data?.count || 0
    } catch (error) {
      console.log('[initEmployeeListByIdentity]:Error', error)
    }
  }
  // 获取岗位员工列表
  const initEmployeeListByPositionId = async (positionId, pageNo, pageSize) => {
    if (!positionId) {
      throw new Error('[initEmployeeListByPositionId]:岗位ID不能为空')
    }
    try {
      employeeState.query.positionNos = positionId
      employeeState.query.pageNo = pageNo || employeeState.query.pageNo
      employeeState.query.pageSize = pageSize || employeeState.query.pageSize
      const result = await getEmployeeList(employeeState.query)
      const { data } = result || {}
      employeeState.list = data?.list || []
      employeeState.count = data?.count || 0
    } catch (error) {
      console.log('[initEmployeeListByPositionId]:Error', error)
    }
  }
  return { employeeState, initEmployeeList, initEmployeeListByIdentity, initEmployeeListByPositionId }
}

// 部门组织结构
export const useDepartment = fn => {
  const state = reactive({
    tree: [],
    current: {},
    treeNode: {}
  })
  const initDepartmentList = (reload = false) => {
    console.log('parent: ', parent)
    //reload:调接口重新加载
    if (!reload && store.state.departmentTreeData.length > 0) {
      state.tree = store.state.departmentTreeData || []
      if (state.tree.length) {
        state.current = state.tree[0]
        typeof fn === 'function' && fn(state.current.id, state.current)
      }
      return
    }
    getTreeDepartment().then(async res => {
      state.tree = (res.data && [res.data]) || []
      store.commit(DEPARTMENT_TREE_DATA, state.tree)
      if (state.tree.length) {
        state.current = state.tree[0]
        typeof fn === 'function' && fn(state.current.id, state.current)
      }
    })
  }
  // 部门点击事件
  const onDeparmentClick = () => {}
  return { state, initDepartmentList, onDeparmentClick }
}

/**
 * 微信鉴权
 */
export const useWxAgent = () => {
  const curUrl = window.location.href
  const initAgen = async () => {
    const url = Base64.encode(curUrl.split('#')[0])
    const result = await getAgentConfig(url)
    if (result && result.data) {
      agentConfig(result.data)
        .then(() => {
          console.log('weixin agent config succuss!!!')
          store.commit(M_IS_WX_VIEW, true)
        })
        .catch(e => {
          console.log('weixin agent config fail:', e)
          store.commit(M_IS_WX_VIEW, false)
        })
    } else {
      store.commit(M_IS_WX_VIEW, false)
    }
  }
  return { initAgen }
}

/**
 * 验证码获取限制
 * @param {*} waiting
 * @returns
 */
export const useVerifyCode = (fn, waiting = 60) => {
  const state = {
    loading: false
  }
  const time = ref(waiting)
  let timer = null
  let timerFn = () => {
    time.value--
    if (time.value === 0) {
      time.value = waiting
      state.loading = false
      clearTimeout(timer)
    } else {
      timer = setTimeout(timerFn, 1000)
    }
  }
  const getCode = async () => {
    if (state.loading) return false
    try {
      state.loading = true
      await fn()
      timer = setTimeout(timerFn, 0)
    } catch (error) {
      state.loading = false
      clearTimeout(timer)
    }
  }
  return { getCode, time }
}

/**
 * 登录日志列表
 * @param {*} pageSize
 * @returns
 */
export const useLoginLogs = (init = false, pageSize = 10) => {
  const logsState = reactive({
    list: [],
    count: 0,
    query: {
      pageSize,
      pageNo: 1,
      status: ''
    },
    loadingState: false
  })
  // 获取日志列表
  const initLoginLogsList = async (pageNo, pageSize) => {
    logsState.loadingState = true
    try {
      logsState.query.pageNo = pageNo || logsState.query.pageNo
      logsState.query.pageSize = pageSize || logsState.query.pageSize
      const result = await loginLog(logsState.query)
      logsState.loadingState = false
      const { data } = result || {}
      logsState.list = data?.list || []
      logsState.count = data?.count || 0
    } catch (error) {
      logsState.loadingState = false
      console.log('[initLoginLogsList]:Error', error)
    }
  }
  if (init) {
    initLoginLogsList()
  }
  return { logsState, initLoginLogsList }
}

/**
 * 系统配置hooks
 * @returns
 */
export const useSystemSetting = () => {
  const settings = computed(() => {
    return store.state.systemSettings
  })
  return settings.value
}

// 退出登录
export const useLogout = (showTip = true) => {
  const deleteConfirm = useDeleteConfirm()
  // const
  const doLogout = async () => {
    try {
      if (showTip && (await deleteConfirm('您确认要退出登录?'))) {
        await logout()
        sessionStorage.clear()
        localStorage.clear()
        router.replace({ name: 'Login' })
      } else if (!showTip) {
        await logout()
        sessionStorage.clear()
        localStorage.clear()
        router.replace({ name: 'Login' })
      }
    } catch (error) {
      console.log('logout error', error)
    }
  }
  return doLogout
}

/**
 * 获取登录状态
 * @returns
 */
export const useLoginStatus = () => {
  const getLoginStatus = async () => {
    try {
      const { isOk } = await isLogin(false)
      return isOk
    } catch (error) {
      return false
    }
  }
  return { getLoginStatus }
}

// 获取微信配置列表
export const getWeChatSettingInfo = (params = {}) => {
  let state = reactive({
    data: {
      id: '',
      companyName: store.state.userInfo.companyName,
      companyNo: store.state.userInfo.companyNo,
      appId: '',
      name: '',
      appSecret: '',
      type: {
        id: params.type,
        desc: params.type == '2' ? '小程序' : '公众号'
      }
    },
    loadingState: false
  })
  const initPageList = async () => {
    state.loadingState = true
    const result = await getWeChatSetting(params)
    if (result.data) {
      if(!result.data.companyName){
        result.data.companyName = store.state.userInfo.companyName
      }
      if(!result.data.companyNo){
        result.data.companyNo = store.state.userInfo.companyNo
      }
      Object.assign(state.data,result.data)
      // state.data = result.data
      
    }
    state.loadingState = false
    return result.data
  }
  return { state, initPageList }
}
