/*
 * @Author: mrchen
 * @Date: 2021-07-01 09:29:30
 * @Last Modified by: mrchen
 * @Last Modified time: 2021-08-18 15:09:52
 * 格式化类
 */
import moment from 'moment'

/**
 *
 * @param {*} date 日期
 * @param {*} partten 格式
 */
export function timeFormat(date, pattern = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) return ''
  return moment(date, moment.DATETIME_LOCAL_SECONDS).format(pattern)
}

/**
 * 获取图片列表
 * @param {*} id
 * @returns
 */
export function getShowUrl(id) {
  return `/file/show/${id}`
}

/**
 * 获取头像
 * @param {*} url
 * @returns
 */
const HTTP_REG = /^http:|https:/
export function getAvatarUrl(url) {
  if (!url) return `${process.env.BASE_URL}images/common/profile-default.png`
  return HTTP_REG.test(url) ? url : url.includes(process.env.BASE_URL) ? location.origin + url : getShowUrl(url)
}
