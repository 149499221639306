export const M_USER_INFO = 'M_USER_INFO'
export const M_IS_WX_VIEW = 'M_IS_WX_VIEW'
// mutations 企业信息
export const M_COMPANY_INFO = 'M_COMPANY_INFO'
// mutations 字典项
export const M_SYSTEM_DICT = 'M_SYSTEM_DICT'
// mutation 配置项
export const M_SYSTEM_SETTING = 'M_SYSTEM_SETTING'
export const A_SYSTEM_SETTING = 'A_SYSTEM_SETTING'

// token
export const M_TOKEN = 'M_TOKEN'

// corp
export const M_CURRENT_CORP = 'M_CURRENT_CORP'
//顶级部门
export const TOP_DEPARTMENT = 'TOP_DEPARTMENT'
//role对象
export const CURRENT_ROLE_STATE = 'CURRENT_ROLE_STATE'
//是否过期
export const EXPIRES = 'EXPIRES'

//模块控制显示项的code集合
export const MODEL_OPEN_LIST = 'MODEL_OPEN_LIST'

//通讯录组织结构树
export const DEPARTMENT_TREE_DATA = 'DEPARTMENT_TREE_DATA'
