import http from '@/common/http'

/**
 * 获取公告列表
 * @param {Object: {pageNo, pageSize} } params
 * @returns
 */
export function getBulletinList(params) {
  return http.post('/micro/crm/message/bulletin/list', params)
}

/**
 * 获取公告详情
 * @param {*} params
 * @returns
 */
export function getBulletinInfo(params) {
  return http.post('/micro/crm/message/bulletin/detail', params)
}

/**
 * 获取消息列表
 * @param {Object: {pageNo,pageSize,successStatus,businessEnum}} params
 * @returns
 */
export function getMessageList(params) {
  return http.post('/micro/crm/message/all/list', params)
}

/**
 * 单条消息设置为已读
 * @param {*} id
 * @returns
 */
export function readMessageById(id) {
  return http.post('/micro/crm/message/business/read', { id })
}

/**
 * 讲消息全部设置为已读
 * @returns
 */
export function readMessageByAll() {
  return http.post('/micro/crm/message/business/read/all')
}

/**
 * 消息配置信息
 * @param {*} params
 * @returns
 */
export function getMessageConfig() {
  return http.get('/micro/crm/message/account/config')
}

/**
 * 设置消息配置
 * @param {*} params
 * @returns
 */
export function updateMessageConfig(params) {
  return http.post('/micro/crm/message/update/account/config', params)
}
