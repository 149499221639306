<template>
  <el-dialog
    custom-class="message-dialog"
    v-model="dialogVisibleState"
    title="账号到期提醒"
    width="482px"
    :before-close="confirmBack"
  >
    <div class="message-text">
      试用已到期，为避免影响您的正常使用，请联系我们
      <div>
        联系电话：
        <span>0755-26910031</span>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="danger" round @click="confirmBack">我知道了</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import * as TYPE from '@/store/types'
export default {
  name: 'messageDialog',
  setup() {
    const store = useStore()
    const state = reactive({
      dialogVisibleState: true
    })
    const confirmBack = () => {
      store.commit(TYPE.EXPIRES, false)
    }
    return {
      ...toRefs(state),
      confirmBack
    }
  }
}
</script>
<style lang="scss">
.message-dialog {
  .el-dialog__header {
    background-color: #f6f6f6;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    padding-bottom: 16px;
  }
  .el-dialog__body {
    box-sizing: border-box;
    height: 108px;
    padding: 28px 50px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    .message-text {
      font-weight: 300;
      color: #666666;
      line-height: 33px;
      font-size: 16px;
      text-align: left;
      span {
        color: #0178ff;
        font-weight: 400;
      }
    }
    .btn-ground {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .el-button {
        width: 317px;
        height: 40px;
      }
    }
  }
}
</style>
