<template>
  <div class="user-avatar">
    <img :round="round" :src="headerUrl" v-if="headerUrl" />
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue'

import { getAvatarUrl } from '@/common/utils/format'
export default defineComponent({
  name: 'userAvatar',
  props: {
    url: {
      type: String
    },
    name: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const headerUrl = computed(() => {
      return getAvatarUrl(props.url)
    })
    return { headerUrl }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
