import { computed, reactive, toRefs } from 'vue'
import * as TYPE from '@/store/types'
import store from '@/store'
import { useDict } from './dict'
import { getLoginInfo } from '@/apis'
import QueryString from 'qs'
import storage from '@/common/storage'
/**
 * 用户信息
 * @returns
 */
export const useUser = () => {
  const state = reactive({
    user: store.state.userInfo || {}
  })
  const setUser = value => {
    state.user = value
    if (value.id) storage.token = value.id
    store.commit(TYPE.M_USER_INFO, value)
  }
  return { ...toRefs(state), setUser }
}

/**
 * 系统初始化调用的一下接口
 */
export const useSystemInit = async () => {
  const { initDict } = useDict()
  const { setUser } = useUser()
  const { data } = await getLoginInfo()
  if (data) {
    setUser(data)
  }
  initDict() // 初始化字典项
  return true
}

/**
 * 判断是否未主管理员
 * @returns
 */
export const useIsAdmin = () => {
  const isAdmin = computed(() => {
    return store.state.userInfo?.roles?.includes('5') || false
  })
  return isAdmin
}

/**
 * 查询参数
 * @returns
 */
export const useQueryParams = () => {
  const url = window.location.search
  const query = url.split('?')[1] || ''
  const { auth_code, code, state } = QueryString.parse(query)
  let realState = state
  // let type = ''
  let corpId = ''
  //先截掉前两位
  const type = state && state.substring(0,1) || '' //取第一个，1_后面的_不需要
  const str = state && state.substring(2,state.length) || '';//示例：1_aaaaaaaa::aaaaaaaa
  if (str && str.indexOf('::') !== -1) {//再降数据进行转换
    ;[realState, corpId] = str.split('::')
  }
  // if (state && state.indexOf('_') !== -1) {
  //   ;[type, realState, corpId] = state.split('_')
  // }
  return {
    state: realState,
    auth_code,
    code,
    type,
    corpId
  }
}
