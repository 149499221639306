import BaseLayout from '@/views/layouts/base-layout/index.vue'
const routes = [
  {
    path: '/start',
    name: 'Start',
    component: () => import('@/views/start/index.vue')
  },
  {
    path: '/',
    name: 'baseLayout',
    component: BaseLayout,
    redirect: { name: 'Start' },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/index.vue') // webpackChunkName 分模块打包
      },
      {
        path: 'apps',
        name: 'Apps',
        component: () => import('@/views/applications/index.vue') // webpackChunkName 分模块打包
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import('@/views/company/index.vue'), // webpackChunkName 分模块打包
        redirect: { name: 'CompanyInfo' },
        children: [
          {
            path: 'info',
            name: 'CompanyInfo',
            component: () => import('@/views/company/info/index.vue')
          },
          {
            path: 'auth',
            name: 'CompanyAuth',
            component: () => import('@/views/company/auth/index.vue')
          },
          {
            path: 'add-invoice/:id?',
            name: 'AddInvoice',
            component: () => import('@/views/company/invoice/index.vue')
          }
        ]
      },

      {
        path: 'address-list',
        name: 'AddressList',
        component: () => import('@/views/address-list/index.vue') // webpackChunkName 分模块打包
      },

      {
        path: 'settings',
        name: 'Settings',
        redirect: { path: '/settings/account-main' },
        component: () => import('@/views/settings/index/index.vue'), // webpackChunkName 分模块打包
        children: [
          {
            path: 'account-main',
            name: 'settingAccountMain',
            component: () => import('@/views/settings/account-main/index.vue')
          },
          {
            path: 'account-child',
            name: 'settingAccountChild',
            meta: {
              group: 'settingAccountChild'
            },
            component: () => import('@/views/settings/account-child/index.vue')
          },
          {
            path: 'dissolution',
            name: 'Dissolution',
            component: () => import('@/views/settings/dissolution/index.vue')
          },
          {
            path: 'role/:id?',
            name: 'Role',
            meta: {
              group: 'settingAccountChild'
            },
            component: () => import('@/views/settings/edit-role/index.vue')
          },
          {
            path: 'users',
            name: 'userManager',
            meta: {
              group: 'userManager'
            },
            component: () => import('@/views/settings/user-manager/index.vue')
          },
          {
            path: 'merchant',
            name: 'merchantManager',
            meta: {
              group: 'merchantManager'
            },
            component: () => import('@/views/settings/merchant/index/index.vue')
          },

          {
            path: 'applet',
            name: 'applet',
            component: () => import('@/views/settings/applet/index.vue')
          },

          {
            path: 'officialAccount',
            name: 'officialAccount',
            component: () => import('@/views/settings/official-account/index.vue')
          },
          {
            path: 'enterpriseWeChat',
            name: 'enterpriseWeChat',
            component: () => import('@/views/settings/enterprise-weChat/index.vue')
          },
          // {
          //   path: 'officialAccount/author/:id?',
          //   name: 'officialAccountAuthor',
          //   component: () => import('@/views/settings/official-account/official-account-author/index.vue')
          // },

          // {
          //   path: 'officialAccount/details/:id?',
          //   name: 'officialAccountDetails',
          //   component: () => import('@/views/settings/official-account/official-account-details/index.vue')
          // },

          {
            path: 'merchant/create/:id?',
            name: 'createMerchant',
            meta: {
              group: 'merchantManager'
            },
            component: () => import('@/views/settings/merchant/create/index.vue')
          },

          {
            path: 'merchant/create/:id?',
            name: 'createMerchant',
            meta: {
              group: 'merchantManager'
            },
            component: () => import('@/views/settings/merchant/create/index.vue')
          },
          {
            path: 'questionnaire',
            name: 'questionnaire',
            component: () => import('@/views/settings/questionnaire/index.vue')
          }
        ]
      },

      {
        path: 'import/:type',
        name: 'Import',
        component: () => import('@/views/data-import/employee-import.vue')
      },
      {
        path: 'user',
        name: 'User',
        redirect: { path: '/user/info' },
        component: () => import('@/views/user/index.vue'),
        children: [
          {
            path: 'info',
            name: 'UserInfo',
            component: () => import('@/views/user/info/index.vue')
          },
          {
            path: 'password',
            name: 'EditPassword',
            component: () => import('@/views/user/password/index.vue')
          },
          {
            path: 'logs',
            name: 'LoginLogs',
            component: () => import('@/views/user/logs/index.vue')
          },
          {
            path: 'notify',
            name: 'SystemNotify',
            meta: { keepAlive: true },
            component: () => import('@/views/user/notify/index.vue')
          },
          {
            path: 'notify/:id/:companyNo?',
            name: 'notifyDetail',
            meta: { keepAlive: true },
            component: () => import('@/views/user/notify/sys-detail.vue')
          },
          {
            path: 'message-settings',
            name: 'MessageSettings',
            component: () => import('@/views/user/message-settings/index.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue')
  }
]

export default routes
