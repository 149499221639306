const dirMap = new WeakMap()
function onInput(el, ele, binding) {
  function handle() {
    // 只保留数字
    ele.value = ele.value.replace(/[^\d]/g, '')
    if (ele.value > dirMap.get(binding.instance)) {
      ele.value = dirMap.get(binding.instance)
    }
  }
  return handle
}
/**
 * 仅支持数字
 */
export const NumberInput = {
  name: 'number',
  created(el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    dirMap.set(binding.instance, binding.arg)
    ele.addEventListener('input', onInput(el, ele, binding, vnode), false)
  },
  beforeUpdate(el, binding) {
    if (dirMap.get(binding.instance) !== binding.arg) {
      const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
      if (ele.value > binding.arg) {
        ele.value = binding.arg
      }
      dirMap.set(binding.instance, binding.arg)
    }
  },
  unmounted(el, binding) {
    // 组件卸载的时候删除weakMap的内容
    dirMap.delete(binding.instance)
  }
}
const SPECIAL_REG = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！%@#￥……&*（）&;|{}【】‘；：”“'。，、？]", 'g')
/**
 * 删除特殊字符字符串
 */
function onSpecialInput(el, ele, binding) {
  function handle() {
    // 只保留数字
    ele.value = ele.value.replace(SPECIAL_REG, '')
    if (ele.value > dirMap.get(binding.instance)) {
      ele.value = dirMap.get(binding.instance)
    }
  }
  return handle
}
export const withoutSpecial = {
  name: 'withoutSpecial',
  created(el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input')
    dirMap.set(binding.instance, binding.arg)
    ele.addEventListener('input', onSpecialInput(el, ele, binding, vnode), false)
  }
}
