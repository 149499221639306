import http from '@/common/http'
/**
 * 获取app列表
 * @returns
 */
export function getApps() {
  return http.post('/sys/menu/groupList')
}

/**
 * 添加使用记录
 * @param {s} menuId
 * @returns
 */
export function addAppUsedRecord(menuId) {
  return http.post('/menu/record/updateAccessTime', { menuId })
}

/**
 * 获取常用应用
 * @returns
 */
export function getCommonlyApps() {
  return http.post('/menu/record/list')
}

/**
 * 发送短信
 * @param {*} mobile
 * @returns
 */
export function sendSms(mobile) {
  return http.post('/sms/sendsms', { mobile })
}

/**
 * 检测企业版本
 * @param {*} corpNo
 * @returns
 */
export function checkCorp(corpNo = '') {
  return http.post('/login/corpCheck', { corpNo })
}
