import { createStore } from 'vuex'
import * as TYPE from './types'
import storage from '@/common/storage'
import { getSetting } from '@/apis'
export default createStore({
  state: {
    userInfo: storage.userInfo || {},
    isWxView: storage.isWxView || false,
    companyInfo: storage.companyInfo || {},
    systemDict: storage.systemDict || {},
    systemSettings: storage.systemSettings || {},
    token: storage.token || '',
    currentCorp: storage.currentCorp || '',
    topDepartment: storage.topDepartment || {},
    departmentTreeData: [], //部门组织结构树
    currentRoleState: storage.currentRoleState || false,
    expires: false, //是否已过期
    modelOpenList: storage.modelOpenList.get() || [] //模块控制可显示的code
  },
  mutations: {
    [TYPE.DEPARTMENT_TREE_DATA](state, departmentTreeData) {
      state.departmentTreeData = departmentTreeData
    },
    [TYPE.M_CURRENT_CORP](state, corp) {
      storage.currentCorp = corp
      state.currentCorp = corp
    },
    [TYPE.M_USER_INFO](state, userInfo) {
      storage.userInfo = userInfo
      state.userInfo = userInfo
    },
    [TYPE.M_IS_WX_VIEW](state, bool) {
      storage.isWxView = bool
      state.isWxView = bool
    },
    [TYPE.M_COMPANY_INFO](state, info) {
      storage.companyInfo = info
      state.companyInfo = info
    },
    [TYPE.M_SYSTEM_DICT](state, dict) {
      storage.systemDict = dict
      state.systemDict = dict
    },
    [TYPE.M_SYSTEM_SETTING](state, settings) {
      storage.systemSettings = settings
      state.systemSettings = settings
    },
    [TYPE.M_TOKEN](state, token) {
      storage.token = token
      state.token = token
    },
    [TYPE.TOP_DEPARTMENT](state, topDepartment) {
      storage.topDepartment = topDepartment
      state.topDepartment = topDepartment
    },
    [TYPE.CURRENT_ROLE_STATE](state, currentRoleState) {
      storage.currentRoleState = currentRoleState
      state.currentRoleState = currentRoleState
    },
    [TYPE.EXPIRES](state, expires) {
      state.expires = expires
    },
    [TYPE.MODEL_OPEN_LIST](state, modelOpenList) {
      storage.modelOpenList.set(modelOpenList)
      state.modelOpenList = modelOpenList
    }
  },
  actions: {
    async [TYPE.A_SYSTEM_SETTING]({ commit }) {
      try {
        const result = await getSetting()
        commit(TYPE.M_SYSTEM_SETTING, result.data)
      } catch (error) {
        console.log('TYPE.A_SYSTEM_SETTING error', error)
      }
    }
  },
  modules: {}
})
