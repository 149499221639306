<template>
  <label :class="['check-icon', { active: value && !disabled }, { disabled: disabled }]"></label>
</template>
<script>
export default {
  name: 'checkIcon',
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.check-icon {
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  line-height: 14px;
  vertical-align: middle;
  cursor: pointer;
  background: white;
  border: 1px solid rgb(220, 223, 230);
  border-radius: 2px;
  font-weight: 500;
  transition-delay: 0s, 0s;
  transition-duration: 0.25s, 0.25s;
  transition-property: border-color, background-color;
  transition-timing-function: cubic-bezier(0.71, -0.46, 0.29, 1.46), cubic-bezier(0.71, -0.46, 0.29, 1.46);
  position: relative;
  &:hover {
    border-color: $primary;
  }
  &.active {
    border-color: $primary;
    background-color: $primary;
    position: relative;
    &::after {
      box-sizing: content-box;
      content: '';
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 4px;
      position: absolute;
      top: 1px;
      width: 3px;
      transition: transform 0.15s ease-in 0.05s, -webkit-transform 0.15s ease-in 0.05s;
      transform-origin: center;
      transform: rotate(45deg) scaleY(1);
    }
  }
  &.disabled {
    border-color: transparent;
    background: transparent;
  }
}
</style>
