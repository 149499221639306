import Server from './server'
import axios from 'axios'
const post = (url, data, messageState = true, baseURL = process.env.VUE_APP_BASE_API, headers) => {
  let obj = {
    url: url,
    method: 'post',
    data,
    messageState: messageState
  }
  if (baseURL) {
    obj.baseURL = baseURL
  }
  if (headers) {
    obj.headers = headers
  }
  return Server.request(obj)
}
const get = (url, query, messageState = true, baseURL = process.env.VUE_APP_BASE_API, headers) => {
  let obj = {
    url: url,
    method: 'GET',
    params: query,
    messageState: messageState
  }
  if (baseURL) {
    obj.baseURL = baseURL
  }
  if (headers) {
    obj.headers = headers
  }
  return Server.request(obj)
}
/**
 * 下载文件
 * @param {*} url
 * @param {*} data
 * @param {*} method
 * @param {*} headers
 */
const exportExcel = (url, data, method, messageState = true, baseURL = process.env.VUE_APP_BASE_API, headers) => {
  let obj = {
    url: url,
    method: method || 'post',
    responseType: 'blob',
    data,
    params: data,
    messageState: messageState
  }
  if (baseURL) {
    obj.baseURL = baseURL
  }
  if (headers) {
    obj.headers = headers
  }
  Server.request(obj).then(res => {
    if (res) {
      let fileName = res.headers['content-disposition'].split('=')[1]
      let blob = new Blob([res.data], {
        type: 'application/octet-stream'
      })

      let link = document.createElement('a')
      let href = window.URL.createObjectURL(blob) // 创建下载的链接
      link.href = href
      if (fileName) {
        link.download = decodeURI(escape(fileName)) // 下载后文件名
      }
      document.body.appendChild(link)
      link.click() // 点击下载
      document.body.removeChild(link) // 下载完成移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
    }
  })
}
/**
 * post 请求
 * @param {url:请求路径, data: 请求数据, headers请求头}
 */
const postForm = function (url, data) {
  return axios.post(url, data)
}
export default {
  post,
  get,
  postForm,
  exportExcel
}
