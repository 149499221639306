import { ElMessageBox } from 'element-plus'
import Sortable from 'sortablejs'
import { nextTick } from 'vue'

export const useMessageBoxConfirm = () => {
  const MessageBoxConfirm = async (message, options, title = '温馨提示') => {
    try {
      return await ElMessageBox.confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        ...options
      })
    } catch (error) {
      return false
    }
  }
  return { MessageBoxConfirm }
}

/**
 * 删除确认框
 * @returns
 */
export const useDeleteConfirm = () => {
  return async (message = '您确定删除该条信息吗?', title = '提示', options = {}) => {
    try {
      await ElMessageBox.confirm(message, title, {
        iconClass: '',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'cofirm-btn',
        cancelButtonClass: 'cancel-btn',
        customClass: 'custom-delete-confirm',
        buttonSize: 'medium',
        ...options
      })
      return true
    } catch (error) {
      return false
    }
  }
}

/**
 * 表单排序有关
 * @param { String } wrapClass // 容器class
 * @param { String } targetClass // 能拖拽的元素类
 * @param { Object } options 配置项
 * @returns
 */
export const useSortTable = (
  wrapClass,
  targetClass,
  appendClass,
  tipAction,
  tipText = '上下移动员工调整位置',
  options = {}
) => {
  let instance = null
  let dom = null
  let parentDom = null
  const htmlStr = `
    <div class="text">${tipText}</div>
    <span class="action" data-key="1">保存</span>
    <span class="action" data-key="2">撤销</span>
  `
  // 立即执行创建一个dom
  ;(() => {
    dom = document.createElement('div')
    dom.className = 'sort-tip-wrap'
    dom.innerHTML = htmlStr
    dom.addEventListener('click', event => {
      const { dataset } = event.target
      if (!dataset || !dataset.key) return false
      if (+dataset.key === 1) {
        const promise = tipAction(dataset.key)
        if (promise instanceof Promise) {
          promise.then(() => removeActionDOM())
        } else if (promise) {
          removeActionDOM()
        }
      } else {
        console.log('instance', instance)
        removeActionDOM()
      }
    })
  })()
  const addActionDOM = () => {
    if (!appendClass) return false
    parentDom = document.querySelector(appendClass)
    parentDom.appendChild(dom)
  }
  function removeActionDOM() {
    if (!parentDom) return false
    parentDom.removeChild(dom)
    instance && instance.option('disabled', true)
  }
  const init = (status = false, fn) => {
    nextTick(() => {
      const el = document.querySelector(wrapClass)
      if (!el) return false
      instance = Sortable.create(el, {
        animation: 150,
        dragClass: 'sortable-drag', // 正在被拖拽中的css类名
        draggable: targetClass, // 允许拖拽的项目类名
        ghostClass: 'sortable-ghost', // drop placeholder的css类名
        chosenClass: 'sortable-chosen', // 被选中项的css 类名
        ...options
      })
      instance.option('disabled', status)
      if (!status) {
        addActionDOM()
      }
      typeof fn === 'function' && fn(!status)
    })
  }
  // 切换的方法
  const trigger = fn => {
    if (!instance) {
      init(fn)
    } else {
      const disabled = instance.option('disabled')
      instance.option('disabled', !disabled)
      // 这里 如果是之前是禁用的,则这次操作表示打开
      if (disabled) {
        addActionDOM()
      } else {
        removeActionDOM()
      }
      typeof fn === 'function' && fn(disabled)
    }
  }
  return { trigger, init, removeActionDOM }
}
