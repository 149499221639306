import { getSystemDict } from '@/apis'
import { M_SYSTEM_DICT } from '@/store/types'
import store from '@/store'

/**
 * 初始化字典项
 */
export const useDict = () => {
  const initDict = async () => {
    try {
      const result = await getSystemDict()
      store.commit(M_SYSTEM_DICT, result.data)
    } catch (error) {
      console.log('initDict Error', error)
    }
  }
  const getDict = type => {
    return store.state.systemDict?.[type] || []
  }
  return { initDict, getDict }
}
