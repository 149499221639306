<template>
  <el-dialog
    width="750px"
    v-model="innerVisible"
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="transfer-select-dialog"
    append-to-body
  >
    <TransferSelect
      ref="transferSelect"
      :visible="innerVisible"
      :nodeKey="nodeKey"
      @search="search"
      @loadedStateBack="loadedStateBack"
      @loadScroll="loadScroll"
      @delItem="delSelectItem"
      @addItem="addItem"
      @cancelCheckAll="cancelCheckAll"
      v-bind="$attrs"
      v-on="{ confirm, cancel }"
    ></TransferSelect>
  </el-dialog>
</template>
<script>
import { computed, ref } from 'vue'
import TransferSelect from './transferSelect.vue'
export default {
  name: 'TransferDialog',
  inheritAttrs: false,
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    nodeKey: {
      type: String
    },
    search: {
      type: Function,
      default: () => () => {}
    },
    loadedStateBack: {
      type: Function,
      default: () => () => {}
    },
    loadScroll: {
      //滚动加载
      type: Function,
      default: () => () => {}
    }
  },
  components: {
    TransferSelect
  },
  emits: ['update:visible', 'confirm', 'cancel'],
  setup(props, { emit }) {
    const transferSelect = ref(null)
    const innerVisible = computed({
      get() {
        return props.visible
      },
      set(val) {
        emit('update:visible', val)
      }
    })
    const setTransferSelectData = (key, data) => {
      //设置当前列表数据
      transferSelect.value.state[key] = data
    }
    const getTransferSelectData = key => {
      //设置当前列表数据
      return transferSelect.value.state[key]
    }
    const setLoadingSelectList = list => {
      //合并右边接口加载和已选数据
      return transferSelect.value.setLoadingSelectList(list)
    }
    const getSelectList = () => {
      //合并右边接口加载和已选数据
      if (transferSelect.value) {
        return transferSelect.value.state.selectList
      }
    }
    const initGetTreeNode = () => {
      //初始化树节点
      transferSelect.value.initGetTreeNode()
    }
    const confirm = list => {
      emit('confirm', list)
      innerVisible.value = false
    }
    const addItem = data => {
      emit('addItem', data)
    }
    const cancel = () => {
      innerVisible.value = false
      emit('cancel')
    }
    const delSelectItem = data => {
      console.log('delSelectItem-------data: ', data)
      emit('delSelectItem', data)
    }
    const cancelCheckAll = data => {
      console.log('cancelCheckAll---data: ', data)
      emit('cancelCheckAll', data)
    }
    const setRightLoading = data => {
      //设置滚动加载动画
      if (transferSelect.value) {
        transferSelect.value.setRightLoading(data)
      }
    }
    return {
      innerVisible,
      confirm,
      cancel,
      transferSelect,
      setTransferSelectData,
      getTransferSelectData,
      initGetTreeNode,
      setLoadingSelectList,
      delSelectItem,
      cancelCheckAll,
      getSelectList,
      setRightLoading,
      addItem
    }
  }
}
</script>
<style lang="scss">
.transfer-select-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    border-radius: 2px;
  }
}
</style>
