import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.scss'
import 'nprogress/nprogress.css'
import 'element-plus/packages/theme-chalk/src/base.scss'
import './styles/element-variables.scss'
import { installComponents } from './plugins/element'
import { getShowUrl } from '@/common/utils/format'
import * as directives from './directives'
const app = createApp(App).use(store).use(router)
// 注册全局指令
Object.values(directives).forEach(item => {
  app.directive(item.name, item)
})
document.title = '物业通'
// 初始化一些全局变量
app.config.globalProperties.$getShowUrl = getShowUrl
app.config.globalProperties.$accessBase = process.env.BASE_URL
app.mount('#micro-base-root')
installComponents(app)
