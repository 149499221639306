import http from '@/common/http'

/**
 * 获取配置信息
 * @returns
 */
export function getSetting() {
  return http.post('/setting')
}

export function getLoginInfo() {
  return http.post('/auth_user/getToken')
}

/**
 * 通过账号密码登录
 * @param {username, password} params
 * @returns
 */
export function loginByPasswd(params) {
  return http.post('/login/login', params)
}
/**
 * 退出登录
 * @returns
 */
export function logout() {
  return http.post('/logout')
}

/**
 * 获取组织架构树
 * 如果传pid 则获取数据
 * @param {*} pid
 * @returns
 */
export function getTreeDepartment(pid) {
  return http.post('/basic/department/tree', { pid })
}
/**
 * 获取组织架构树延迟加载树
 * 如果传parent_id 则获取数据
 * @param {*} parent_id
 * @returns
 */
export function getLazyTreeDepartment(params) {
  return http.post('/basic/department/lazyTree', params)
}

/**
 * 获取department List
 * @param {Array} id
 * @param {*} isAll
 * @returns
 */
export function getDepartmentList(id, name = '', isAll = false, options = {}) {
  return http.post('/basic/department/list', { isAll, id, name, ...options })
}

/**
 * 获取企业员工和部门
 * @param {*} obj
 * @returns
 */
export function getCommunityTransfer(obj) {
  return http.post('/basic/department/getDepartmentEmpTransfer', obj)
}
/**
 * 获取部门信息
 * @param {*} obj
 * @returns
 */
export function getDepartmentTree(obj) {
  return http.post('/basic/department/getDepartment', obj)
}

/**
 * 获取导入历史
 * @returns
 */
export function getImportHistoryList() {
  return http.post('/basic/employee/upload/log')
}

/**
 * 文件上传
 * @param {*} file
 * @returns
 */
export function uploadFileApi(file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', file)
    http
      .postForm('/file/upload', formData)
      .then(({ data }) => {
        if (data.code === 200) {
          resolve(data)
        } else {
          reject(new Error('uploader error'))
        }
      })
      .catch(reject)
  })
}

/**
 * 获取附件信息
 * @param {*} id
 * @returns
 */
export const getFileInfo = id => {
  return http.post('/file/get', { id })
}

/**
 * 获取子管理列表
 * @param {*} params
 * @returns
 */
export function getRoleList(params) {
  return http.post('/sys/role/list', params)
}

/**
 * 获取子管理员列表 (角色)
 * @returns
 */
export function getChildRoleList(params) {
  return http.post('/auth_user/getChildAmind', params)
}

/**
 * 删除角色(子管理员)
 * @param {*} id 角色Id
 * @param {Array} employeeIds 员工ids
 * @returns
 */
export function deleteRole(id, employeeIds) {
  return http.post('/sys/role/delete', { id, oldEmployees: employeeIds })
}

/**
 *新增角色
 * @param {*} params
 * @returns
 */
export function addRole(params) {
  return http.post('/sys/role/save', params)
}

/**
 * 更新角色信息
 * @param {*} params
 * @returns
 */
export function updateRole(params) {
  return http.post('/sys/role/update', params)
}

/**
 * 获取角色信息
 * @param {string} id 角色Id
 * @returns
 */
export function getRole(id) {
  return http.post('/sys/role/get', { id })
}
/**
 * 获取应用版本显示隐藏
 * @param {string} id 公司No
 * @returns
 */
export function getVersion(params) {
  return http.post('/sys/corp/module/version/get', params)
}

/**
 * 获取管理员
 * @returns
 */
export function getAdminList() {
  return http.post('/auth_user/getAdmin')
}

/**
 * 转让主管理员
 * @param { code,newUserId,oldUserId } params
 * @returns
 */
export function transferAdmin(params) {
  return http.post('/auth_user/transfer', params)
}

/**
 * 验证码判断
 * @param {*} params
 * @returns
 */
export function checkedSms(params) {
  return http.post('/sms/checkSmsCode', params)
}

/**
 * 获取角色-职务列表
 * @param {*} params(无参，默认全量数据)
 * @returns
 */
export function getPositionList(params) {
  return http.post('/basic/position/list', params)
}

/**
 * 角色-岗位-新增员工（批量调整员工职务部门）
 * @param {*} params
 * @returns
 */
export function updateEmployeePosition(params) {
  return http.post('/basic/employee/updatePosition', params)
}

/**
 * 获取权限细分
 * @returns
 */
export function getMenuPromisesTree(id) {
  return http.post('/sys/menu/getMenuSubdivide', { id })
}

/**
 * 获取banner列表
 * @param {*} endTime
 * @returns
 */
export function getAdsList(endTime) {
  return http.post('/sys/advert/list', { endTime })
}

/**
 * 新增角色-岗位
 * @param {*} params
 * @returns
 */
export function addPosition(params) {
  return http.post('/basic/position/save', params)
}

/**
 * 编辑角色-岗位
 * @param {*} params
 * @returns
 */
export function updatePosition(params) {
  return http.post('/basic/position/update', params)
}

/**
 * 删除角色-岗位
 * @param {*} params
 * @returns
 */
export function deletePosition(id) {
  return http.post('/basic/position/delete', { id })
}

/**
 * 更新角色-岗位位置（sort）
 * @param {*} params
 * @returns
 */
export function updatePositionSort(params) {
  return http.post('/basic/position/updateSort', params)
}

/**
 * 获取日志记录
 * @param {*} params
 * @returns
 */
export function getLogsList(params) {
  return http.post('/sys/log/list', params)
}

/**
 * 获取系统字典项
 * @returns
 */
export function getSystemDict() {
  return http.post('/basic/employee/enums')
}

/**
 * 解散企业
 * @param {*} id
 * @returns
 */
export const deleteCompany = code => {
  return http.post('/sms/dissolve', { code })
}

/**
 * 修改登录密码
 * @param {*} params
 * @returns
 */
export function updatePassword(params) {
  return http.post('/auth_user/changePasswd', params)
}

/**
 * 登录日志（默认个人）
 * @param {*} params
 * @returns
 */
export function loginLog(params) {
  return http.post('/login/log', params)
}

/**
 * 判断登录状态
 * @returns
 */
export function isLogin(messageState = true) {
  return http.post('/isLogin', {}, messageState)
}

export function loginByTencentQrcode(corpId, code) {
  return http.post('/login/tencent/login', { corpId, code })
}

export function loginByOAuth2(corpId, code) {
  return http.post('/login/tencent/oauth/inner/login', { corpId, code })
}

/**
 * 获取用户信息
 * @param {*} id
 * @returns
 */
export function getUserInfo(id) {
  return http.post('/auth_user/get', { id })
}

/**
 * 更新用户信息
 * @param {*} params
 * @returns
 */
export function updateUserInfo(params) {
  return http.post('/auth_user/update', params)
}

/**
 * 更新引导页提示时间
 * @param {*} id
 * @returns
 */
export function updateGuideTime(id) {
  return http.post('/auth_user/remind', { id })
}

/**
 * 获取商户
 * @param {*} params
 * @returns
 */
export function getMerchantList(params) {
  return http.post('/setting/merchant/corp/page', params)
}
/**
 * 获取所有商户
 * @returns
 */
export function getAllMerchant() {
  return http.post('/setting/merchant/corp/list')
}

/**
 * 新建商户
 * @param {*} params
 * @returns
 */
export function createMerchant(params) {
  return http.post('/setting/merchant/corp/save', params)
}

/**
 * 更新商户
 * @param {*} params
 * @returns
 */
export function updateMerchant(params) {
  return http.post('/setting/merchant/corp/update', params)
}

/**
 * 获取商户信息
 * @param {*} id
 * @returns
 */
export function getMerchantById(id) {
  return http.post('/setting/merchant/corp/get', { id })
}

/**
 * 部分更新商户（商户审核）
 * @param {*} params
 * @returns
 */
export function auditMerchant(params) {
  return http.post('/setting/merchant/corp/auditUpdate', params)
}

/**
 * 删除商户
 * @param {*} id
 * @returns
 */
export function deleteMerchant(id) {
  return http.post('/setting/merchant/corp/delete', { id })
}

/**
 * 获取所有小区列表
 * @returns
 */
export function getAllCommunityList(params) {
  return http.post('/basic/community/listAll', params)
}

/**
 * 获取用户列表
 * @param {*} params
 * @returns
 */
export function getUserList(params) {
  return http.post('/auth_user/list', params)
}

/**
 * 更改状态
 * @param {*} params
 * @returns
 */
export function updateUserStatus(params) {
  return http.post('/auth_user/updateStautus', params)
}

/**
 * 删除用户
 * @param {*} id
 * @returns
 */
export function deleteUser(id) {
  return http.post('/auth_user/delete', { id })
}

/**
 * 导出用户
 * @returns
 */
export function exportUser(params) {
  return http.exportExcel('/auth_user/export', params)
}
/**
 * 获取模块控制显示隐藏
 * @param {*} params
 * @returns
 */
export const getModelOpenList = data => {
  return http.post('/enterprise/module/open_list', data, true, '/kht-bin')
}

/**
 * 创建微信配置
 * @param {*} params
 * @returns
 */
export const saveWechatManage = data => {
  return http.post('/wechat/setting/manage/save', data)
}

/**
 * 获取微信内建企业
 * @param {*} params
 * @returns
 */
export const getCorpData = data => {
  return http.post('/wwin/corp/getCorp', data)
}
/**
 * 创建微信内建企业
 * @param {*} params
 * @returns
 */
export const saveCorp = data => {
  return http.post('/wwin/corp/save', data)
}
//更新
export const updateCorp = data => {
  return http.post('/wwin/corp/update', data)
}
/**
 * 编辑微信配置
 * @param {*} params
 * @returns
 */
export const updateWechatManage = data => {
  return http.post('/wechat/setting/manage/update', data)
}
/**
 * 微信配置列表
 * @param {*} params
 * @returns
 */
export const getWechatPage = data => {
  return http.post('/wechat/setting/manage/page', data)
}
/**
 * 获取公众号小程序配置
 * @param {*} params
 * @returns
 */
export const getWeChatSetting = data => {
  return http.post('/wechat/setting/manage/getWeChatSetting', data)
}
/**
 * 获取公众号小程序配置
 * @param {*} params
 * @returns
 */
export const saveOrUpdate = data => {
  return http.post('/wechat/setting/manage/saveOrUpdate', data)
}
/**
 * 获取当前部门下的人员
 * @param {*} departmentId
 * @returns
 */
export const getIncrementalTreeEmployeeNode = data => {
  return http.post('/basic/department/incrementalTreeEmployeeNode', data)
}
/**
 * 获取已选超管列表
 * @param {*} id
pageNo
pageSize
 * @returns
 */
export const getRoleUser = data => {
  return http.post('auth_user/listPageByRole', data)
}
/**
 * 初始化数据
 * @returns
 */
export function getIndexData() {
  return http.post('/index')
}

export function treeRootDepartment({ pid, pageNo, pageSize }) {
  return http.post('/basic/department/incrementalTreeRoot', { pid, pageNo, pageSize })
}
export function treeEmployeeDepartment(obj) {
  return http.post('/basic/department/incrementalTreeEmployeeNode', obj)
}
export function treeNodeDepartment(obj) {
  return http.post('/basic/department/incrementalTreeNode', obj)
}
// 调查问卷设置
export function savePlatformConfig(obj) {
  return http.post('/crm/naire/platform/config/save', obj)
}
//问卷调查微信配置查询
export function findByCompanyNo(obj) {
  return http.post('/crm/naire/platform/config/findByCompanyNo', obj)
}