<template>
  <el-config-provider :locale="zhCn">
    <template v-if="isCurrentRoute">
      <router-view />
    </template>
    <template v-else>
      <div id="container"></div>
    </template>
  </el-config-provider>
  <MessageDialog v-if="dialogVisible" @confirm="confirm" />
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, reactive, watch, toRefs } from 'vue'

import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { useQueryParams, useSystemInit } from './hooks'
import MessageDialog from '@/components/message/index.vue'
import { useLoginStatus } from './hooks/sys'
// import { A_SYSTEM_SETTING } from './store/types'
import { useStore } from 'vuex'
import { checkCorp } from './apis'

export default defineComponent({
  components: { [ElConfigProvider.name]: ElConfigProvider, MessageDialog },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      dialogVisible: store.state.expires
    })
    watch(
      () => store.state.expires,
      async val => {
        console.log('val: ', val)
        state.dialogVisible = val
      },
      { immediate: true }
    )
    const isCurrentRoute = computed(() => {
      const bool = !(route.path && route.path.includes('/appChild'))
      return bool
    })
    const { getLoginStatus } = useLoginStatus()
    // store.dispatch(A_SYSTEM_SETTING)//获取设置信息
    if (!window.location.href.includes('code=')) {
      //不是企业微信跳进来的
      ;(async () => {
        const { corpId } = useQueryParams()
        const { data } = await checkCorp(corpId)
        const isLogin = await getLoginStatus()
        if (isLogin && data) {
          await useSystemInit()
        } else {
          localStorage.clear()
          router.replace('/login')
        }
      })()
    }
    const confirm = data => {
      state.dialogVisible = data
    }

    return {
      ...toRefs(state),
      isCurrentRoute,
      zhCn,
      confirm
    }
  }
})
</script>
<style lang="scss">
#container {
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
