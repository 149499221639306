import {
  ElAlert,
  ElButton,
  ElInput,
  ElSelect,
  ElEmpty,
  ElDialog,
  ElCarousel,
  ElInfiniteScroll,
  ElLoading,
  ElMessage,
  ElMessageBox,
  ElNotification,
  ElForm,
  ElFormItem,
  ElRadioGroup,
  ElRadioButton,
  ElRadio,
  ElTree,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElUpload,
  ElProgress,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElPopover,
  ElOption,
  ElSwitch,
  ElCarouselItem,
  ElTooltip,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElDatePicker,
  ElCalendar,
  ElCascader,
  ElDrawer,
  ElCard,
  ElTabs,
  ElTabPane
} from 'element-plus'
// import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
// import locale from 'element-plus/lib/locale'

import Avatar from '@/components/avatar'
import WxView from '@/components/wx-view'
import TransferSelect from '@/components/transfer-dialog'
const components = [
  ElTabs,
  ElTabPane,
  ElCard,
  ElAlert,
  ElButton,
  ElInput,
  ElSelect,
  ElOption,
  ElForm,
  ElFormItem,
  ElRadioGroup,
  ElRadioButton,
  ElRadio,
  ElEmpty,
  ElDialog,
  ElCheckbox,
  ElCheckboxButton,
  ElCheckboxGroup,
  ElTree,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElUpload,
  ElProgress,
  ElPopover,
  ElSwitch,
  ElCarousel,
  ElCarouselItem,
  ElTooltip,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElDatePicker,
  ElCalendar,
  ElCascader,
  ElDrawer,
  Avatar,
  WxView,
  TransferSelect
]
const plugins = [ElInfiniteScroll, ElLoading, ElMessage, ElMessageBox, ElNotification]
/**
 * 按需引入组件
 * @param app
 */
export function installComponents(app) {
  // 设置语言
  // locale.use(lang)
  plugins.forEach(plugin => {
    app.use(plugin)
  })
  components.forEach(component => {
    app.component(component.name, component)
  })
}
