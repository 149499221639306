import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'
import NProgress from 'nprogress'
import store from '@/store'
import { useCompany } from '@/hooks/company'
// import { clearPending } from '@/common/http/request'
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, form, next) => {
  console.log('to:-------------- ', to)
  // clearPending()
  if (!store.state.token) {
    // sessionStorage.clear()
    // localStorage.clear()
  }
  if (
    !store.state.token &&
    to.name !== 'Login' &&
    form.path.indexOf('/start') === -1 &&
    !window.location.href.includes('code=')
  ) {
    console.error('需要重新登陆哦')
    next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
  } else {
    NProgress.start()
    if (
      store.state.token &&
      to.name !== 'Login' &&
      form.path.indexOf('/start') === -1 &&
      to.path.indexOf('/home') === -1 &&
      to.path.indexOf('/start') === -1 &&
      to.path.indexOf('/company/info') === -1
    ) {
      //登录后不在login和start
      const { initCompanyInfo } = useCompany()
      initCompanyInfo()
    }
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})
export default router
