<template>
  <div class="base-layout">
    <nav>
      <div class="nav-container">
        <div class="logo">
          <img :src="logo" alt="" />
        </div>
        <div class="menus">
          <div
            class="menu-item"
            @click="menuClick(menu)"
            :class="{ active: active === menu.id }"
            v-for="menu in menus"
            :key="menu.id"
          >
            <div class="item-text">{{ menu.name }}</div>
          </div>
        </div>
        <div class="user-wrapper">
          <div class="avatar-wrap">
            <userAvatar :url="userInfo.headUrl"></userAvatar>
          </div>
          <div class="action-wrap">
            <div class="action-item" @click.stop="routerTo('/user/info')">
              <div class="text">资料设置</div>
            </div>
            <div class="action-item" @click.stop="routerTo('/user/password')">
              <div class="text">修改密码</div>
            </div>
            <div class="action-item" @click.stop="routerTo('/user/logs')">
              <div class="text">登录记录</div>
            </div>
            <div class="action-item" @click.stop="routerTo('/user/notify')">
              <div class="text">系统通知</div>
            </div>
            <div class="action-item" @click.stop="routerTo('/user/message-settings')">
              <div class="text">消息设置</div>
            </div>
            <div class="action-item border-top" @click.stop="onLogOutClick">
              <div class="text">退出登录</div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <main id="layoutMain">
      <router-view v-slot="{ Component }">
        <component :is="Component"></component>
      </router-view>
    </main>
    <footer>
      Copyright 2021 深圳市客户通科技有限公司 京ICP备
      <a href="javascript:;">16045432号-4</a>
    </footer>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onUnmounted, ref, watch, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useLogo } from '@/hooks/company'
import { useLogout } from '@/hooks/sys'
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const logout = useLogout()
    const state = reactive({
      menus: [
        { id: 'Home', name: '首页', route: '/home' },
        { id: 'Apps', name: '应用管理', route: '/apps' }
      ]
    })
    const logo = useLogo()
    const userInfo = computed(() => {
      return store.state.userInfo
    })
    /**
     * 根据权限设置菜单
     */
    const setMenus = () => {
      // 如果通讯录存在
      if (userInfo.value.newsletterState) {
        state.menus.splice(1, 0, { id: 'AddressList', name: '通讯录', route: '/address-list' })
      }
      // 我的企业
      if (userInfo.value.businessState) {
        state.menus.push({ id: 'Company', name: '我的企业', route: '/company' })
      }
      // 设置
      if (userInfo.value.settingState) {
        state.menus.push({ id: 'Settings', name: '设置', route: '/settings' })
      }
      let list = new Set(state.menus)
      state.menus = [...list]
    }
    setMenus()

    const active = ref(null)
    const menuClick = item => {
      active.value = item.id
      router.push({ path: item.route })
    }
    const routerTo = path => {
      if (path) {
        router.push({ path })
      }
    }
    const onLogOutClick = () => {
      logout()
    }
    const stop = watch(
      () => route.path,
      path => {
        if (!path) return false
        for (const item of state.menus) {
          if (path.startsWith(item.route)) {
            active.value = item.id
            return false
          } else {
            active.value = ''
          }
        }
      },
      { immediate: true }
    )
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    return {
      ...toRefs(state),
      logo,
      userInfo,
      active,
      menuClick,
      routerTo,
      onLogOutClick
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
