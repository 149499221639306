const USER_TOKEN = 'kht-user-token'
const USER_INFO = 'kht-user-info'
const IS_WX_VIEW = 'kht-is-wx-view'
const SYSTEM_DICT = 'kht-system-dict'
const SYSTEMS_ETTINGS = 'kht-system-settings'
const CURRENT_CORP = 'kht-current-corp'
const TOP_DEPARTMENT = 'top-department'
const CURRENT_ROLE = 'current_role_state'
const MODELOPENLIST = 'model-open-list'
// 这里利用对象属性的get 和set方法对数据sessionStroage数据进行
const storage = {
  get userInfo() {
    const value = localStorage.getItem(USER_INFO)
    if (value === 'undefined') return {}
    return value ? JSON.parse(value) : {}
  },
  set userInfo(value) {
    localStorage.setItem(USER_INFO, JSON.stringify(value))
  },
  get isWxView() {
    const value = localStorage.getItem(IS_WX_VIEW)
    if (value === 'undefined') return false
    return value ? JSON.parse(value) : false
  },
  set isWxView(value) {
    localStorage.setItem(IS_WX_VIEW, JSON.stringify(value))
  },
  get systemDict() {
    const value = localStorage.getItem(SYSTEM_DICT)
    if (value === 'undefined') return false
    return value ? JSON.parse(value) : false
  },
  set systemDict(value) {
    localStorage.setItem(SYSTEM_DICT, JSON.stringify(value))
  },
  get systemSettings() {
    const value = localStorage.getItem(SYSTEMS_ETTINGS)
    if (value === 'undefined') return {}
    return (value && JSON.parse(value)) || {}
  },
  set systemSettings(value) {
    localStorage.setItem(SYSTEMS_ETTINGS, JSON.stringify(value))
  },
  get token() {
    const value = localStorage.getItem(USER_TOKEN)
    return value ? value : ''
  },
  set token(value) {
    localStorage.setItem(USER_TOKEN, value)
  },
  get currentCorp() {
    return localStorage.getItem(CURRENT_CORP) || ''
  },
  set currentCorp(value) {
    localStorage.setItem(CURRENT_CORP, JSON.stringify(value))
  },
  get topDepartment() {
    return localStorage.getItem(TOP_DEPARTMENT) ? JSON.parse(localStorage.getItem(TOP_DEPARTMENT)) : {}
  },
  set topDepartment(value) {
    localStorage.setItem(TOP_DEPARTMENT, JSON.stringify(value))
  },
  get currentRole() {
    return localStorage.getItem(CURRENT_ROLE) ? JSON.parse(localStorage.getItem(CURRENT_ROLE)) : {}
  },
  set currentRole(value) {
    localStorage.setItem(CURRENT_ROLE, JSON.stringify(value))
  },
  modelOpenList: {
    get() {
      const value = localStorage.getItem(MODELOPENLIST)
      return value === 'false' ? false : true || ''
    },
    set(val) {
      localStorage.setItem(MODELOPENLIST, val)
    }
  }
}

export default storage
