import http from '@/common/http'
// 获取企业信息
export const getCompanyInfo = () => {
  return http.post('/sys/corp/currentCorp')
}

// 获取企业员工列表(账号列表))
export const getAccountList = () => {
  return http.post('/auth_user/list', { pageNo: 1, pageSize: 500 })
}

// 获取企业统计信息
export const getCompanyCount = () => {
  return http.post('/basic/department/departInfoCount', {})
}

/**
 * 更新logo
 * @param {*} id 企业Id
 * @param {*} logo logo
 * @returns
 */
export const updateCompanyInfo = (id, params) => {
  return http.post('/sys/corp/update', { id, ...params })
}

/**
 * 添加发票抬头
 * @param {*} params
 * @returns
 */
export const saveInvoiceTitle = params => {
  return http.post('/sys/invoice/save', params)
}

/**
 * 修改发票抬头
 * @param {*} params
 * @returns
 */
export const updateInvoiceTitle = params => {
  return http.post('/sys/invoice/update', params)
}

/**
 * 获取发票抬头信息
 * @param {*} id
 * @returns
 */
export const getInvoiceTitle = params => {
  return http.post('/sys/invoice/get', params)
}

/**
 * 获取发票抬头信息
 * @param {*} id
 * @returns
 */
export const getInvoiceList = params => {
  return http.post('/pay/charge/fapiao/list', params)
}
