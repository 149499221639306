import { getCompanyCount, getCompanyInfo } from '@/apis'
import { getShowUrl } from '@/common/utils/format'
import { M_COMPANY_INFO } from '@/store/types'
import { reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import store from '@/store'

/**
 * 企业信息
 * @param {*} init
 * @returns
 */
export const useCompany = (init = false) => {
  const state = reactive({
    logo: '', // 企业logo
    companyName: '', // 企业名称
    shortName: '', // 企业简称
    address: '', // 企业地址
    phone: '', // 联系电话
    domain: '', // 企业域名
    employeeNumber: 0, // 企业成员
    departmentNumber: 0, // 企业部门数字
    maxNumber: 200, //人数上限
    useCount: 1, // 已使用人数
    invoiceInfo: {},
    industry: '', // 行业
    scale: '', // 人员规模
    createTime: '',
    id: '',
    loadingState: false
  })
  const { companyCount, initCompanyCount } = useCompanyCount()
  const initCompanyInfo = async () => {
    state.loadingState = true
    const result = await getCompanyInfo()
    setTimeout(() => {
      state.loadingState = false
    }, 500)
    const { data } = result
    const { corp, invoice } = data || {}
    if (typeof corp.pattern.id !== 'undefined') {
      localStorage.setItem('cropPattern', corp.pattern.id)
    }
    localStorage.setItem('corpLogo', corp?.logo ?? '')
    state.logo = corp?.logo ?? '' // 企业logo
    state.khtLogo = corp?.khtLogo ?? '' // 自己的logo
    state.companyName = corp?.fullName ?? '' // 全称
    state.shortName = corp?.name ?? '' // 企业简称
    state.address = corp?.address ?? '' // 联系地址
    state.phone = corp?.phone ?? '' // 联系点哈
    state.domain = corp?.url ?? '' // 公司网站
    state.industry = corp?.type ?? '' // 行业类型
    state.scale = corp?.staffSize ?? '' // 人员规模
    state.createTime = corp?.createTime ?? '' // 创建时间
    state.id = corp?.id ?? ''
    state.corpId = corp?.corpId ?? ''
    state.invoiceInfo = invoice
    await initCompanyCount()
    state.employeeNumber = companyCount.value.employeeCount // 企业人员
    state.departmentNumber = companyCount.value.departCount // 企业部门数
    store.commit(M_COMPANY_INFO, { ...state })

    return state
  }
  if (init) {
    initCompanyInfo()
  }
  return {
    state,
    initCompanyInfo,
    companyCount
  }
}

/**
 * 用户统计信息
 * @param {Boolean} init 是否默认直接初始化接口
 * @returns
 */
export function useCompanyCount(init = false) {
  const state = reactive({
    companyCount: {}
  })
  const initCompanyCount = async () => {
    try {
      const { data } = await getCompanyCount()
      state.companyCount = data || {}
    } catch (error) {
      console.log('error', error)
    }
  }

  if (init) {
    initCompanyCount()
  }
  return { ...toRefs(state), initCompanyCount }
}

/**
 * 自定义获取显示头像logo
 * @returns
 */
export const useLogo = () => {
  const store = useStore()
  const logo = computed(() => {
    return store.state.companyInfo.khtLogo ? getShowUrl(store.state.companyInfo.khtLogo) : store.state.companyInfo.logo
  })
  return logo
}
