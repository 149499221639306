import http from '@/common/http'
/**
 * 获取单个员工信息
 * @param {*} id
 * @returns
 */
export const getEmployeeInfo = id => {
  return http.post('/basic/employee/get', { id })
}
/**
 * 获取列表
 * @param { department, pageSize, pageNo, name, mobile, id: Array } params
 * @returns
 */
export function getEmployeeList(params) {
  return http.post('/basic/employee/list', params)
}

/**
 * 获取所有员工列表
 * @param {*} params
 * @returns
 */
export function getAllEmployeeList(params) {
  return http.post('/basic/employee/listAll', params)
}

/**
 * 添加员工列表
 * @param {*} params
 * @returns
 */
export function saveEmployee(params) {
  return http.post('/basic/employee/save', params)
}

/**
 * 编辑员工信息
 * @param {*} params
 * @returns
 */
export function updateEmployee(params) {
  return http.post('/basic/employee/update', params)
}

/**
 * 删除员工
 * @param {*} id
 * @returns
 */
export function delEmployee(params) {
  return http.post('/basic/employee/delete', params)
}

/**
 * 批量删除员工
 * @param {*} ids
 * @returns
 */
export function batchDelEmployee(params) {
  return http.post('/basic/employee/batch/delete', params)
}

/**
 * 批量调整员工部门信息
 * @param {*} params
 * @returns
 */
export function batchChangeEmployeeDepartment(params) {
  return http.post('/basic/employee/updateDepartment', params)
}

/**
 * 员工排序
 * @param {*} params
 * @returns
 */
export function sortEmployee(params) {
  return http.post('/basic/employee/updateSort', params)
}

/**
 * 导出部门信息
 * @param {Boolean} isModel
 * @param {Array} department
 * @returns
 */
export function exportTemplate(isModel, department) {
  return http.exportExcel('/basic/employee/export', { isModel, department })
}

/**
 * 导入员工
 * @param {*} fileId
 * @returns
 */
export function importEmployee(fileId) {
  return http.post('/basic/employee/upload', { fileId })
}

/**
 * 下载失败记录
 * @param {Array} items
 * @returns
 */
export function exportErrorLog(items) {
  return http.exportExcel('/basic/employee/log/export', { items })
}

/**
 * 上传员工
 * @param {*} fileId
 * @returns
 */
export function syncEmployeeImport(fileId) {
  return http.post('/basic/department/readFile', { fileId })
}

/**
 * 人员或者部门搜索
 * @param {*} name
 * @returns
 */
export function searchEmployeeOrDepart(name) {
  return http.post('/basic/employee/merge/list', { name })
}

/**
 * 导入同步信息
 * @param {*} fileId
 * @returns
 */
export function importAsyncFile(fileId) {
  return http.post('/wwout/department/sync/importFile', { fileId })
}

/**
 * 导出通讯录
 * @returns
 */
export function exportAsyncFile() {
  return http.post('/wwout/department/sync/exportFile')
}

/**
 * 同步通讯录部门
 * @returns
 */
export function corpDepartmentSyn() {
  return http.get('/wwin/corp/department/syn')
}
/**
 * 同步通讯录部门员工
 * @returns
 */
export function corpEmployeeSyn() {
  return http.get('/wwin/corp/employee/syn')
}
/**
 * 同步通讯录
 * @returns
 */
export function syncDeptAndEmp() {
  return http.get('/wwin/corp/syncDeptAndEmp')
}
/**
 * 查询当前企业是否配置员工密钥
 * @returns
 */
export function checkEmployeeSecretIsNull() {
  return http.post('/wwin/corp/checkEmployeeSecretIsNull')
}
