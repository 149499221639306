import axios from 'axios'
import QS from 'qs'
import { ElLoading, ElMessage } from 'element-plus'
import store from '@/store/index'
import router from '@/router/index'

class Server {
  constructor() {
    this.Loading = null
    this.errMessage = {
      400: '错误请求',
      401: '未授权',
      403: '资源不可用',
      404: '找不到资源',
      500: '服务器内部错误',
      502: '网关错误',
      503: '服务不可用',
      504: '网关超时',
      505: 'HTTP 版本不受支持'
    }
    this.pendingMap = new Map()
    this.config = {
      timeout: 900000,
      crossDomain: true,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }
  }

  checkStatus(response) {
    const status = response.status || -1000 // -1000 自己定义，连接错误的status
    const data = response.data

    if ((status >= 200 && status < 300) || status === 304) {
      if (data.code !== 200 && response.config.responseType !== 'blob') {
        return {
          status,
          errorState: true,
          data: response,
          msg: this.errMessage[data.code] || '请求异常'
        }
      } else if (response.config.responseType === 'blob' && data.type === 'application/json') {
        const self = this
        return new Promise((resolve, reject) => {
          var reader = new FileReader()
          reader.readAsText(response.data, 'utf-8')
          reader.onload = function () {
            response.data = JSON.parse(reader.result)
            if (response.data) {
              resolve({
                status,
                errorState: true,
                data: response,
                msg: self.errMessage[data.code]
              })
            } else {
              // prefer-promise-reject-errors
              reject(response)
            }
          }
        })
      } else {
        return response.data
      }
    } else {
      return {
        status,
        errorState: true,
        msg: this.errMessage[data.code] || '请求异常'
      }
    }
  }

  getUrlKey(config) {
    if (!config) return ''
    return [config.method, config.url, QS.stringify(config.params), QS.stringify(config.data)].join('&')
  }
  removePending(config) {
    const key = this.getUrlKey(config)
    if (!this.pendingMap.has(key)) {
      const cancel = this.pendingMap.get(key)
      typeof cancel === 'function' && cancel()
      this.pendingMap.delete(key)
    }
  }
  clearPending() {
    for (const [url, cancel] of this.pendingMap) {
      typeof cancel === 'function' && cancel(url)
    }
    this.pendingMap.clear()
  }
  addPending(config) {
    // eslint-disable-next-line no-unused-vars
    const key = this.getUrlKey(config)
    // config.cancelToken =
    //   config.cancelToken ||
    //   new axios.CancelToken(cancel => {
    //     if (!pendingMap.has(key)) {
    //       pendingMap.set(key, cancel)
    //     }
    //   })
  }
  /**
   * 数据处理
   */
  handleResponse(response, options) {
    const { data } = response
    if (data instanceof Blob) {
      if (data.type === 'application/json') {
        let reader = new FileReader()
        reader.addEventListener('loadend', function () {
          let result = JSON.parse(reader.result)
          if (result.code === 401) {
            alert()
          } else {
            // alert(result.message)
            ElMessage({
              showClose: true,
              message: result.message,
              type: 'warning'
            })
          }
          return null
        })
        reader.readAsText(data, ['utf-8'])
        return response
      } else {
        ElMessage({
          showClose: true,
          message: '下载成功',
          type: 'info'
        })
        return response
      }
    } else {
      if (data.code === 301) {
        store.state.expires = true
      } else if (data.code === 444) {
        return Promise.reject({ message: '登陆超时' })
      } else {
        // 直接返回 需要的数据，其余状态在这里判断
        if (data.isOk) {
          // return resData.data || resData.result
          return data
        } else {
          return this.handlerError(data, options)
        }
      }
    }
  }

  /**
   * 处理系统异常
   */
  handlerError(result, options) {
    if (result.code === 201) {
      this.toLoginPage()
    } else {
      if (options.messageState) {
        ElMessage({
          showClose: true,
          message: result.message,
          type: 'error'
        })
      }
      // return Promise.reject(new MyError(result.code, result.message))
      return result
    }
  }
  setInterceptors(instance, options) {
    // request请求拦截器
    instance.interceptors.request.use(
      config => {
        if (options.loading) {
          this.Loading = ElLoading.service()
        }
        this.addPending(config) // 将当前请求添加到 pending 中
        if (config.url.startsWith('/mock')) {
          config.baseURL = ''
        } else if (config.url.startsWith('/thirdLogin')) {
          config.baseURL = ''
        } else if (config.url.startsWith('/file')) {
          config.baseURL = ''
        }
        return config
      },
      error => {
        if (options.loading) {
          this.Loading.close()
        }
        Promise.reject(error)
      }
    )

    // response 响应拦截器
    instance.interceptors.response.use(
      async response => {
        if (options.loading) {
          this.Loading.close()
        }
        this.removePending(response.config) // 在请求结束后，移除本次请求
        const { status, data } = response

        if (data && data.code === 201) {
          if (options.messageState) {
            ElMessage({
              showClose: true,
              message: '未登录,请先登录',
              type: 'error'
            })
          }
          router.replace({
            path: '/login',
            query: { redirect: encodeURIComponent(router.currentRoute.value.fullPath) }
          })
        } else if (status === 200) {
          return this.handleResponse(response, options)
        } else {
          Promise.reject(new Error(`request error code: ${status}`))
        }
        return response
      },
      error => {
        if (options.loading) {
          this.Loading.close()
        }
        if (error.response) {
          return Promise.reject(this.checkStatus(error.response))
        } else {
          if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            error.msg = '请求超时'
            ElMessage({
              message: '请求超时',
              type: 'warning'
            })
            return Promise.reject(error)
          } else {
            if (typeof error.message.message !== 'undefined') {
              ElMessage({
                message: error.message.message,
                type: 'warning'
              })
            } else {
              ElMessage.error(error.message)
            }
            return Promise.reject(error)
          }
        }
      }
    )
  }

  async request(options) {
    // 每次请求都会创建新的axios实例。
    const instance = axios.create()
    let config = {}
    config.baseURL = process.env.VUE_APP_BASE_API
    config.crossDomain = this.config.crossDomain
    config.withCredentials = this.config.withCredentials
    config.timeout = this.config.timeout
    config.headers = this.config.headers
    config.messageState = options.messageState || true
    config = Object.assign(config, options)

    // 配置拦截器
    await this.setInterceptors(instance, config)
    return instance(config) // 返回axios实例的执行结果
  }
}

export default new Server()
