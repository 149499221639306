const wx = window.wx
const jsApiList = [
  'sendChatMessage',
  'getCurExternalContact',
  'selectEnterpriseContact',
  'getCurExternalChat',
  'openDefaultBrowser',
  'scanQRCode',
  'getLocation',
  'openLocation'
]
export function wxConfig(item, onSuccuss, onError) {
  console.log('=========wx.config...=========', item)
  wx.config({
    beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: item.corpid, // 必填，企业微信的corpID
    timestamp: item.config_timestamp, // 数字, 必填，生成签名的时间戳
    nonceStr: item.config_nonceStr, // 必填，生成签名的随机串
    signature: item.config_signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList: jsApiList // [] 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  })

  wx.ready(function () {
    console.log('=========wx.config ok=========')
    onSuccuss()
    // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
  })

  wx.error(function (res) {
    console.log('=========wx.config fail=========', res)
    onError(res)
    // alert('wx.config err:' + JSON.stringify(res))
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  })
}

export function scanQRCode(onSuccuss) {
  // needResult 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
  // scanType 可以指定扫二维码还是条形码（一维码），默认二者都有
  wx.invoke(
    'scanQRCode',
    {
      desc: 'scanQRCode desc',
      needResult: 1,
      scanType: ['qrCode', 'barCode']
    },
    function (res) {
      if (res.err_msg == 'scanQRCode:ok') {
        //返回当前外部联系人userId
        onSuccuss(res)
      }
    }
  )
}

export function checkPermission(item, callback, onError) {
  wx.checkJsApi({
    jsApiList: jsApiList, // 需要检测的JS接口列表，所有JS接口列表见附录2,
    success: function (res) {
      // 以键值对的形式返回，可用的api值true，不可用为false
      // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      if (res.errMsg === 'checkJsApi:ok') {
        callback(res)
      } else {
        onError(res)
        // alert("wx.checkJsApi err:" + JSON.stringify(res))
      }
    }
  })
}
export function injiectPermission(item, onSuccuss, onError) {
  wx.agentConfig({
    corpid: item.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
    agentid: item.appId, // 必填，企业微信的应用id （e.g. 1000247）
    timestamp: item.agent_timestamp, // 必填，生成签名的时间戳
    nonceStr: item.agent_nonceStr, // 必填，生成签名的随机串
    signature: item.agent_signature, // 必填，签名，见附录1
    jsApiList: jsApiList, //必填
    success: function (res) {
      onSuccuss(res) // 回调
    },
    fail: function (res) {
      onError(res)
      // alert('wx.agentConfig err:' + JSON.stringify(res))
    }
  })
}

/**
 * 获取当前选中联系人
 * @param onSuccuss 1,客户；2;客户群
 * @param onError
 */
export function getCurContact(onSuccuss) {
  let self = this
  wx.invoke('getCurExternalContact', {}, function (res) {
    if (res.err_msg == 'getCurExternalContact:ok') {
      //返回当前外部联系人userId
      onSuccuss(res)
    } else {
      //alert("getCurExternalContact err: " + JSON.stringify(res))
      self.getCurChatRoom(onSuccuss)
    }
  })
}

/**
 * 获取当前选中群
 * @param onSuccuss
 * @param onError
 */
export function getCurChatRoom(onSuccuss) {
  wx.invoke('getCurExternalChat', {}, function (res) {
    if (res.err_msg == 'getCurExternalChat:ok') {
      // chatId  = res.chatId ; //返回当前客户群的群聊ID
      onSuccuss(res)
    } else {
      // alert("getCurChat err: " + JSON.stringify(res))
      //错误处理
    }
  })
}

/**
 * 打开默认浏览器
 * @param onSuccuss
 */
export function openBrower(url) {
  wx.invoke(
    'openDefaultBrowser',
    {
      url: url // 在默认浏览器打开redirect_uri，并附加code参数；也可以直接指定要打开的url，此时不会附带上code参数。
    },
    function (res) {
      if (res.err_msg != 'openDefaultBrowser:ok') {
        //错误处理
      }
    }
  )
}

/**
 * 发送消息
 *
 * 包括文本(“text”)，图片(“image”)，视频(“video”)，文件(“file”)以及H5(“news”）。
 * @param msg
 * @param onSuccuss
 * @param onError
 */
export function sendMessage(msgType, msg) {
  console.log(this.getMsgContent(msgType, msg))
  wx.invoke('sendChatMessage', this.getMsgContent(msgType, msg), function (res) {
    console.log(res)
    if (res.err_msg != 'sendChatMessage:ok') {
      //发送失败
    }
  })
}
export function getMsgContent(msgType, msg) {
  switch (msgType) {
    case 'text':
      return {
        msgtype: msgType, //消息类型，必填
        text: {
          content: msg.content //文本内容
        }
      }
    case 'image':
      return {
        msgtype: msgType, //消息类型，必填
        image: {
          mediaid: msg.media_id //图片的素材id
        }
      }
    case 'video':
      return {
        msgtype: msgType, //消息类型，必填
        video: {
          mediaid: msg.media_id //图片的素材id
        }
      }
    case 'file':
      return {
        msgtype: msgType, //消息类型，必填
        file: {
          mediaid: msg.media_id //图片的素材id
        }
      }
    case 'news':
      return {
        msgtype: msgType, //消息类型，必填
        news: {
          link: msg.shareUrl, //H5消息页面url 必填
          title: msg.name ? msg.name : '', //H5消息标题
          desc: msg.desc ? msg.desc : '', //H5消息摘要
          imgUrl: this.getFileDomain(msg.fileId) //H5消息封面图片URL
        }
      }
    case 'graphic':
      return {
        msgtype: 'news', //消息类型，必填
        news: {
          link: this.getGraphicDomian(msg.id), //H5消息页面url 必填
          title: msg.name ? msg.name : '', //H5消息标题
          desc: msg.desc ? msg.desc : '', //H5消息摘要
          imgUrl: this.getFileDomain(msg.fileId) //H5消息封面图片URL
        }
      }
    default:
      return { error: 'errortype' }
  }
}
export function openChatPanel(userId, externaluserId, callBack) {
  wx.openEnterpriseChat({
    // 注意：userIds和externalUserIds至少选填一个，且userIds+externalUserIds总数不能超过2000。
    userIds: userId, //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
    externalUserIds: externaluserId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
    groupName: '讨论组', // 必填，会话名称。单聊时该参数传入空字符串""即可。
    success: function (res) {
      // 回调
      callBack(res)
    },
    fail: function (res) {
      if (res.errMsg.indexOf('function not exist') > -1) {
        alert('版本过低请升级')
      }
    }
  })
}
/**
 * 打开选人界面
 * @param cannback
 */
export function openContactPanel(cannback) {
  wx.invoke(
    'selectExternalContact',
    {
      filterType: 0 //0表示展示全部外部联系人列表，1表示仅展示未曾选择过的外部联系人。默认值为0；除了0与1，其他值非法。在企业微信2.4.22及以后版本支持该参数
    },
    function (res) {
      if (res.err_msg == 'selectExternalContact:ok') {
        console.log('selectExternalContact', res)
        cannback(res.userIds) //返回此次选择的外部联系人userId列表，数组类型
      } else {
        // alert(JSON.stringify(res))
        //错误处理
      }
    }
  )
}

/**
 * 选择人员接口
 * @param {*} mode 模式
 * @param {*} types 类型
 * @param {*} selectedUserIds 已选人员Id
 * @param {*} selectedDepartmentIds 已选组织Id
 * @returns
 */
export function selectEnterpriseContact(
  mode = 'multi',
  types = ['user'],
  selectedUserIds = [],
  selectedDepartmentIds = []
) {
  return new Promise((resolve, reject) => {
    wx.invoke(
      'selectEnterpriseContact',
      {
        fromDepartmentId: 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        mode, // 必填，选择模式，single表示单选，multi表示多选
        type: types, // 必填，选择限制类型，指定department、user中的一个或者多个
        selectedDepartmentIds, // 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        selectedUserIds // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
      },
      function (res) {
        if (res.err_msg == 'selectEnterpriseContact:ok') {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result) //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          resolve(res.result)
          // var selectedDepartmentList = res.result.departmentList // 已选的部门列表
          // for (var i = 0; i < selectedDepartmentList.length; i++) {
          //   var department = selectedDepartmentList[i]
          //   var departmentId = department.id // 已选的单个部门ID
          //   var departemntName = department.name // 已选的单个部门名称
          // }
          // var selectedUserList = res.result.userList // 已选的成员列表
          // for (var i = 0; i < selectedUserList.length; i++) {
          //   var user = selectedUserList[i]
          //   var userId = user.id // 已选的单个成员ID
          //   var userName = user.name // 已选的单个成员名称
          //   var userAvatar = user.avatar // 已选的单个成员头像
          // }
        } else {
          reject(new Error('selectEnterpriseContact error'))
        }
      }
    )
  })
}
export function shareMessage(obj) {
  wx.invoke(
    'shareAppMessage',
    obj || {
      title: '你好吗', // 分享标题
      desc: '谢谢', // 分享描述
      link: 'https://baidu.com', // 分享链接
      imgUrl: 'https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2534506313,1688529724&fm=26&gp=0.jpg' // 分享封面
    },
    function (res) {
      console.log(res)
      // if (res.err_msg === "shareAppMessage:ok") {
      // }
    }
  )
}

export function getQueryString(name) {
  let reg = `(^|&)${name}=([^&]*)(&|$)`
  let r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}
export function getQueryParams() {
  const args = {}

  let query = window.location.search.substr(1)
  let pairs = query.split('&')
  for (let i = 0; i < pairs.length; i++) {
    let pos = pairs[i].indexOf('=')
    if (pos == -1) continue
    let name = pairs[i].substring(0, pos)
    let value = pairs[i].substring(pos + 1)
    value = decodeURIComponent(value)
    args[name] = value
  }
  return args
}
export function getOauth2(state, corpid, page) {
  let workurl =
    'https://open.weixin.qq.com/connect/oauth2/authorize?' +
    'appid=' +
    corpid +
    '&redirect_uri=' +
    getDomain(page) +
    '&response_type=code'
  if (state.indexOf('_') > 0) {
    workurl += '&scope=snsapi_userinfo'
  } else {
    // 内建应用固定为snsapi_base
    workurl += '&scope=snsapi_base'
  }
  workurl += '&state=' + state + '#wechat_redirect'
  return workurl
}
export function getDomain(page) {
  return window.location.protocol + '//' + window.location.host + '/weixin?page=' + page
}
export function getHttpsDomain(page) {
  return 'https://' + window.location.host + '/weixin?page=' + page
}
export function getFileDomain(page) {
  return 'http://' + window.location.host + '/file/show/' + page
}
export function getGraphicDomian(page) {
  return 'http://' + window.location.host + '/weixin/#/graphic/show?id=' + page
}
export function isWorkWeixin() {
  var ua = window.navigator.userAgent.toLowerCase()
  // 企业微信
  // if ((ua.match(/MicroMessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork') ){
  //     return true
  // }
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}
export function setHtmlTitle(title) {
  document.title = title
  var mobile = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(mobile)) {
    var iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    // 替换成站标favicon路径或者任意存在的较小的图片即可
    iframe.setAttribute('src', '/favicon.ico')
    var iframeCallback = function () {
      setTimeout(function () {
        iframe.removeEventListener('load', iframeCallback)
        document.body.removeChild(iframe)
      }, 0)
    }
    iframe.addEventListener('load', iframeCallback)
    document.body.appendChild(iframe)
  }
}

export function getLocation() {
  return new Promise((resovle, reject) => {
    wx.getLocation({
      type: 'gcj02',
      success: function (res) {
        if (res) {
          const location = { ...res }
          resovle(location)
        } else {
          reject(new Error('未获取到经纬度'))
        }
      }
    })
  })
}
export function openLocation(location, address) {
  return new Promise(resolve => {
    wx.openLocation({
      latitude: location.lat, // 纬度，浮点数，范围为90 ~ -90
      longitude: location.lng, // 经度，浮点数，范围为180 ~ -180。
      name: address, // 位置名
      address: address, // 地址详情说明
      scale: 16 // 地图缩放级别,整形值,范围从1~28。默认为16
    })
    resolve()
    // getLocation()
    //   .then(res => {
    //     resolve()
    //   })
    //   .catch(err => {
    //     reject(err)
    //   })
  })
}

export function agentConfig(item) {
  return new Promise((resolve, reject) => {
    window.wx.agentConfig({
      corpid: item.corpid, // 必填，企业微信的corpID
      agentid: item.appId,
      timestamp: item.timestamp, // 数字, 必填，生成签名的时间戳
      nonceStr: item.nonceStr, // 必填，生成签名的随机串
      signature: item.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList: [], // [] 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      success: function (res) {
        resolve(res)
      },
      fail: function (error) {
        if (error.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级')
        }
        reject(error)
      }
    })
  })
}

export default {}
