<template>
  <div class="transfer-select-wrapper">
    <div class="ts-title">
      {{ title }}
    </div>
    <div class="ts-container">
      <!-- 左侧数据区域 -->
      <section v-loading="state.departmentLoading">
        <div class="title">选择:</div>
        <div class="origin" :class="{ more: state.navList.length > 1 }">
          <el-input
            placeholder="请输入内容"
            v-model="state.searchText"
            @keyup.enter="onSearch"
            clearable
            @clear="onSearch"
            size="small"
          >
            <template @click="onSearch" #prefix>
              <i @click="onSearch" style="cursor: pointer" class="el-input__icon el-icon-search"></i>
            </template>
          </el-input>
          <div
            v-if="selectType !== 'user' && state.topSelecedState"
            class="label-ground"
            @click="itemChange('top', topList)"
          >
            <el-checkbox
              fill="#0178ff"
              v-if="!single"
              :model-value="topList?.checked"
              @change="itemChange('top', topList)"
            ></el-checkbox>
            <div class="name">
              <span type="departmentName" :openid="topList[labelKey]">{{ topList[labelKey] }}</span>
            </div>
          </div>
          <el-breadcrumb type="primary" v-else separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
              @click.stop="crumbClick(nav, index)"
              v-for="(nav, index) in state.navList"
              :key="nav.id"
            >
              <slot name="crum-item">
                <span type="departmentName" :openid="nav[labelKey]">{{ nav[labelKey] }}</span>
                <!-- {{ getNavText(nav[labelKey]) }} -->
              </slot>
            </el-breadcrumb-item>
          </el-breadcrumb>

          <div class="check-all" v-if="!single">
            <el-checkbox
              :model-value="checkAll"
              :disabled="state.selectIdList.length > 0 && selectDisabledState"
              @change="checkAllChange"
            >
              全选
            </el-checkbox>
            <span>
              总人数: {{ state.allPersonCount }} / 已选:{{ currentSelectNum }} / 未选:{{
                state.allPersonCount - currentSelectNum
              }}
            </span>
          </div>
          <div
            class="origin-list"
            v-infinite-scroll="leftLoadScroll"
            infinite-scroll-immediate="false"
            style="overflow: auto"
          >
            <div
              class="item-wrapper"
              v-for="item in state.currentList"
              :key="item.id"
              @click="itemChange('bottom', item)"
              :style="{
                paddingLeft: single ? '0' : '2px',
                backgroundColor: item.checked && !checkStrictly ? '#e6e6e6' : ''
              }"
            >
              <div class="item-wrapper-left">
                <check-icon :disabled="isDisabled(item)" v-if="!single" :value="item.checked" />
                <div name="origin-item" :item="item">
                  <span class="name">{{ item[labelKey] }}</span>
                </div>
              </div>
              <div v-if="isSelectable(item)">
                <span
                  :class="['child-icon', { disabled: item.checked && !checkStrictly }]"
                  v-if="item[childrenKey]"
                  @click.stop="getChildren(item)"
                >
                  下级
                </span>
              </div>
            </div>
            <div v-if="state.currentList.length === 0">暂无数据</div>
          </div>
        </div>
      </section>
      <!-- 右侧已经选择居于 -->
      <section v-loading="state.rightLoading">
        <div class="title">已选:</div>
        <div
          class="target"
          v-infinite-scroll="rightLoadScroll"
          infinite-scroll-immediate="false"
          style="overflow: auto"
        >
          <div class="target-item" v-for="sItem in state.selectList" :key="sItem.id">
            <slot name="target-item" :item="sItem">
              <template v-if="sItem[typeKey] === 'user'">
                <user-item :labelKey="labelKey" :user="sItem"></user-item>
              </template>
              <template v-else>
                <div class="name">
                  <span type="departmentName" :openid="sItem[labelKey]">{{ sItem[labelKey] }}</span>
                </div>
              </template>
            </slot>
            <span class="icon el-icon-circle-close" @click="delItem(sItem)"></span>
          </div>
          <div v-if="state.selectList.length === 0" class="target-item">暂无数据</div>
        </div>
      </section>
    </div>
    <div class="ts-footer">
      <el-button type="primary" size="small" @click="handleOk">确认</el-button>
      <el-button type="default" size="small" @click="handleCancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import CheckIcon from './checkIcon.vue'
import UserItem from './userItem.vue'
import { getLazyTreeDepartment, getIncrementalTreeEmployeeNode } from '@/apis'
export default {
  name: 'transferSelect',
  inheritAttrs: false,
  components: {
    CheckIcon,
    UserItem
  },
  emits: ['confirm', 'cancel', 'search'],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      topSelecedState: true,
      searchText: '',
      selectList: [],
      propSelectList: [], //初始化传进来的缓存
      selectIdList: [],
      crumb: [],
      currentList: [],
      currentListOrigin: [], //请求数据源
      // 面包屑列表
      navList: [],
      departmentLoading: false,
      rightLoading: false,
      lazyTreePage: {
        pageNo: 1,
        pageSize: 100
      },
      lazyTreeLoadState: true,
      lazyTreeList: [], //树形部门
      employeePage: {
        pageNo: 1,
        pageSize: 100
      },
      employeeLoadState: true,
      employeePageList: [], //员工列表
      treeNodeLoadState: true,
      allPersonCount: 0
    })

    const topList = computed(() => {
      return store.state.topDepartment
    })
    const currentSelectNum = computed(() => {
      return state.currentList.filter(item => {
        return item.checked
      }).length
    })
    const setSelectOptionsChecked = () => {
      //设置选中状态
      if (state.currentList.length > 0 && state.selectList.length > 0) {
        state.currentList.map(item => {
          state.selectList.map(c => {
            if (item.id === c.id) {
              item.checked = true
            }
          })
        })
      }
    }

    //获取部门树
    const getTreeNodeList = parent_id => {
      getLazyTreeDepartment(Object.assign(state.lazyTreePage, { parent_id: parent_id }))
        .then(res => {
          console.log('res: ', res)
          let list = res.data.list
          console.log('list: ', list)
          state.lazyTreeLoadState = !(list.length < state.lazyTreePage.pageSize)
          if (list && list.length > 0) {
            list.map(item => {
              item['type'] = 'department'
              item[props.labelKey] = item.name
              if (props.selectType === 'user') {
                item['disabled'] = true
              }
            })
            state.lazyTreeList = state.lazyTreePage.pageNo === 1 ? list : state.lazyTreeList.concat(list)
            if (state.lazyTreePage.pageNo === 1) {
              state.currentList = list
            } else {
              state.currentList = state.lazyTreeList.concat(state.employeePageList)
            }
          } else {
            state.lazyTreeList = []
          }
          if (
            state.lazyTreePage.pageNo === 1 &&
            parent_id &&
            typeof parent_id !== 'undefined' &&
            props.selectType === 'user'
          ) {
            console.log('props.selectType: ', props.selectType)
            //初始化时调取同级的员工
            state.employeePage.pageNo = 1
            getEmployeeList(parent_id)
          } else {
            state.departmentLoading = false
          }
        })
        .catch(() => {
          state.departmentLoading = false
        })
    }
    //获取员工列表
    const getEmployeeList = departmentId => {
      getIncrementalTreeEmployeeNode(Object.assign(state.employeePage, { departmentId: departmentId }))
        .then(res => {
          console.log('res: ', res)
          let list = res.data.list || []
          state.allPersonCount = res.data.count
          state.employeeLoadState = !(list.length < state.employeePage.pageSize)
          if (list && list.length > 0) {
            list.map(item => {
              item['type'] = 'user'
              item[props.labelKey] = item.name
            })
            state.employeePageList = state.employeePage.pageNo === 1 ? list : state.employeePageList.concat(list)
          } else {
            state.employeePageList = state.employeePage.pageNo === 1 ? [] : state.employeePageList
          }
          console.log('state.employeePageList: ', state.employeePageList)
          state.currentList = state.lazyTreeList.concat(state.employeePageList)
          setSelectOptionsChecked()
          state.departmentLoading = false
        })
        .catch(() => {
          state.departmentLoading = false
        })
    }

    const initGetTreeNode = () => {
      //获取一级部门节点
      state.departmentLoading = true
      getTreeNodeList(topList.value.id)
    }

    initGetTreeNode()
    state.propSelectList = JSON.parse(JSON.stringify(props.selecteds))
    state.navList.push({
      [props.labelKey]: topList.value.name,
      [props.nodeKey]: topList.value.id,
      rootState: true
    })
    // 获取导航栏文字
    const getNavText = value => {
      if (!value || typeof value !== 'string') return ''
      return value.length > 4 ? `${value.substr(0, 4)}...` : value
    }
    const checkAll = computed(() => {
      const canSelectedList = state.currentList.filter(item => item[props.typeKey] === props.selectType)
      return canSelectedList.length && state.currentList.filter(item => item.checked).length === canSelectedList.length
    })

    // 全选改变的时候
    const checkAllChange = value => {
      console.log('checkAllChange-------value: ', value)

      // 获取当前单位已经选择的Id是
      const ids = state.selectList.map(item => item[props.nodeKey])
      // 表示是否需要删除的数据
      if (!value) {
        //取消选择
        console.log('ids: ', ids)
        //成员
        let delList = state.currentList.filter(item => {
          if (item[props.typeKey] === 'user') {
            return item
          }
        })
        emit('cancelCheckAll', delList)
      }
      const removeIds = []
      state.currentList.forEach(item => {
        if (item[props.typeKey] === props.selectType) {
          item.checked = value
          if (value) {
            // 如果是中状态, 如果列表里面没有则添加
            if (!ids.includes(item[props.nodeKey])) {
              emit('addItem', item)
              state.selectList.push(item)
            }
          } else {
            // 标记删除
            removeIds.push(item[props.nodeKey])
          }
        }
      })
      // 如果存在需要删除的数据，则进行删除
      if (removeIds.length) {
        state.selectList = state.selectList.filter(item => !removeIds.includes(item[props.nodeKey]))
      }
    }
    // 子选项选择的时候
    const itemChange = (type, current) => {
      if (current[props.typeKey] === 'department' && props.selectType === 'user') {
        getChildren(current)
        return
      }
      // 如果item的类型根数据不一样 直接返回

      if (current.isDisabled) {
        return
      }

      const status = !current.checked
      current.checked = status
      if (type === 'top' && current[props.typeKey] !== props.selectType) {
        //是顶级部门
        if (status) {
          if (!state.selectList.find(item => item.id === current.id)) {
            state.selectList.push(current)
            emit('addItem', current)
          } else if (props.single) {
            //单选时清空已选
            state.selectList = []
          }
        } else {
          emit('delItem', current)
          state.selectList = state.selectList.filter(item => item.id !== current.id)
        }
      }

      if (!props.single) {
        if (current[props.typeKey] !== props.selectType) return false
        if (status) {
          if (!state.selectList.find(item => item.id === current.id)) {
            state.selectList.push(current)
            emit('addItem', current)
          }
        } else {
          emit('delItem', current)
          state.selectList = state.selectList.filter(item => item.id !== current.id)
        }
      } else {
        // 单选模式
        // 重置上个选项为非选中状态
        if (state.selectList.length > 0) {
          const last = state.selectList[0]
          if (last) {
            last.checked = false
          }
        }
        state.selectList = current.checked ? [current] : []
      }
    }
    //删除已选数据
    const delItem = delItem => {
      state.selectList = state.selectList.filter(item => item.id !== delItem.id)
      state.currentList.forEach(item => {
        if (item.id === delItem.id) {
          item.checked = false
        }
      })
      emit('delItem', delItem)
    }

    // 获取子项目
    const getChildren = async item => {
      if ((item.checked && !props.single && !props.checkStrictly) || (item.checked && props.single)) {
        if (!props.single) {
          item.checked = false
        }
        return false
      }

      state.topSelecedState = false
      state.departmentLoading = true
      state.lazyTreePage.pageNo = 1
      state.treeNodeLoadState = true
      getTreeNodeList(item.id)
      state.navList.push(item)
    }
    const crumbClick = async (nav, index) => {
      state.lazyTreePage.pageNo = 1
      state.treeNodeLoadState = true
      if (state.navList.length - 1 === index) {
        //最后一级
        if (props.single) {
          //单选时
          state.departmentLoading = true
          state.lazyTreePage.pageNo = 1
          state.treeNodeLoadState = true
          getTreeNodeList(nav.id)
        }
        nav.__changeType__ = true
        return
      }
      if (!nav.rootState) {
        state.topSelecedState = false
      } else {
        //是顶级目录时
        state.topSelecedState = true
      }
      state.navList = state.navList.slice(0, index + 1)
      nav.__changeType__ = true
      state.departmentLoading = true
      state.lazyTreePage.pageNo = 1
      state.treeNodeLoadState = true
      getTreeNodeList(nav.id)
    }
    const handleOk = () => {
      emit('confirm', state.selectList)
    }
    const handleCancel = () => {
      emit('cancel')
    }
    const onSearch = () => {
      emit('search', state.searchText)
    }
    const isDisabled = item => {
      //是否禁用
      let flag = true
      if (props.selectDisabledState) {
        //有选择禁用
        let ids = props.selectableList.map(c => c[props.selectKey])

        flag = ids.length > 0 ? !ids.includes(item[props.selectKey]) : false
        item.isDisabled = flag
        return flag
      } else {
        flag = item[props.typeKey] !== props.selectType

        item.isDisabled = flag
        return flag
      }
    }
    const isSelectable = item => {
      //是否可选
      let flag = props.selectableList.some(c => c[props.selectKey] === item[props.selectKey])
      if (props.selectDisabledState) {
        return flag
      } else {
        return true
      }
    }
    const rightLoadScroll = () => {
      //滚动加载
      emit('loadScroll')
    }
    const leftLoadScroll = () => {
      //左侧滚动加载
      console.log('leftLoadScroll----')
      console.log('state.lazyTreeLoadState: ', state.lazyTreeLoadState)
      if (state.lazyTreeLoadState) {
        state.lazyTreePage.pageNo += 1
        getTreeNodeList(state.navList[state.navList.length - 1].id)
      }
      console.log('state.employeeLoadState: ', state.employeeLoadState)
      if (state.employeeLoadState && props.selectType === 'user') {
        state.employeePage.pageNo += 1
        getEmployeeList(state.navList[state.navList.length - 1].id)
      }
    }
    const setRightLoading = data => {
      //设置滚动加载与已选的合并
      state.rightLoading = data
    }

    const setLoadingSelectList = list => {
      //设置滚动加载与已选的合并
      state.selectList = [...new Set(props.selectableList.concat(list))]
    }
    watch(
      () => props.lists,
      val => {
        if (Array.isArray(val) && val.length > 0) {
          val.map(item => {
            state.selectList.map(c => {
              if (item.id === c.id) {
                item.checked = true
              }
            })
          })
        }
        state.currentList = Array.isArray(val) ? val : []

        if (state.navList.length === 1) {
          state.navList[0][props.childrenKey] = state.currentList
        }
      },
      { immediate: true }
    )
    watch(
      () => state.departmentLoading,
      val => {
        if (!val) {
          emit('loadedStateBack')
        }
      }
    )
    watch(
      () => topList.value,
      val => {
        state.navList[0].name = val.name
        state.navList[0][props.nodeKey] = props.id
      }
    )
    watch(
      () => props.visible,
      val => {
        if (val) {
          state.selectList = JSON.parse(JSON.stringify(props.selecteds)) || []
        }
      },
      { immediate: true }
    )
    watch(
      () => state.selectList,
      val => {
        state.selectIdList = val.length > 0 ? val.map(item => item[props.selectKey]) : []
        if (val.length > 0) {
          setSelectOptionsChecked()
        }
      },
      { immediate: true }
    )

    return {
      state,
      getNavText,
      crumbClick,
      handleOk,
      handleCancel,
      delItem,
      getChildren,
      checkAllChange,
      itemChange,
      checkAll,
      onSearch,
      isDisabled,
      isSelectable,
      topList,
      initGetTreeNode,
      leftLoadScroll,
      rightLoadScroll,
      setLoadingSelectList,
      setRightLoading,
      currentSelectNum
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      // 标题
      type: String,
      default: ''
    },
    selectType: {
      // 选择类型
      type: String,
      default: 'item'
    },
    selectKey: {
      // 选中的字段
      type: String,
      default: 'id'
    },
    selectDisabledState: {
      //禁用除已选之外的选项
      type: Boolean,
      default: false
    },
    topDepartment: {
      //顶级部门
      type: Object,
      default: () => {
        return {}
      }
    },
    selectableList: {
      //可选列表
      type: Array,
      default: () => {
        return []
      }
    },
    loadScrollList: {
      //右侧滚动加载列表
      type: Array,
      default: () => {
        return []
      }
    },
    typeKey: {
      // 类型key
      type: String,
      default: 'type'
    },
    lists: {
      // 源数据
      type: Array,
      required: true
    },
    rootName: {
      // 根名称
      type: String,
      default: 'root'
    },
    rootId: {
      // 根的Id
      type: [String, Number],
      default: 'root'
    },
    nodeKey: {
      // 唯一key值
      type: String,
      default: 'id'
    },
    single: {
      // 是否单选
      type: Boolean,
      default: false
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    childrenKey: {
      type: String,
      defeault: 'children'
    },
    // 父子不关联模式
    checkStrictly: {
      type: Boolean,
      default: false
    },
    nextFn: Function,
    navClick: Function,
    selecteds: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
@import './transfer.scss';
</style>
<style lang="scss">
.el-breadcrumb {
  margin: 8px 0 !important;
  .el-breadcrumb__item {
    &:not(:last-child) {
      .el-breadcrumb__inner {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
</style>
