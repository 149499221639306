<template>
  <div class="transfer-user-item">
    <div class="user-icon">
      <userAvatar :url="user.headerUrl"></userAvatar>
    </div>
    <div class="user-name">
      <wxview type="userName" :openid="user[labelKey]">{{ user[labelKey] }}</wxview>
    </div>
  </div>
</template>
<script>
export default {
  name: 'transferUserItem',
  inheritAttrs: false,
  props: {
    user: {
      type: Object,
      required: true
    },
    labelKey: {
      type: String,
      default: 'name'
    }
  },
  setup(props) {
    const getRandomColor = () => {
      return '#' + Math.floor(Math.random() * 0xffffff).toString(16)
    }
    const getIconText = () => {
      return props.user[props.labelKey].substring(props.user[props.labelKey].length - 2)
    }
    return { getRandomColor, getIconText }
  }
}
</script>
<style lang="scss" scoped>
.transfer-user-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 10px;
  flex: 1;
  .user-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 0;
    flex-shrink: 0;
    margin-right: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    .text-icon {
      color: white;
      width: 100%;
      height: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .user-name {
    font-size: 14px;
  }
}
</style>
